import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';

import FormGroup from '@material-ui/core/FormGroup';

import Checkbox from '@material-ui/core/Checkbox';

import { useWizard } from '../Components/WizardContext'

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    main:{
      paddingLeft:10,
      paddingRight:10,
      paddingTop: 20
    },
    button:{
      marginTop:15, 
      marginBottom:15,
      marginLeft:15
    }, 
    tablerow: {
      height: 33
    }
  });

function GAPeriodo() {

    const classes                       = useStyles();
    const { wizardData, setWizardData } = useWizard()

    useEffect( ()=>{
        return () => {
        }; 
    },[])

  return (
    <React.Fragment>
        <div className={classes.root}>
            <Grid container spacing={1}>
                <FormLabel component="legend">Seleccione los días...</FormLabel>
                <FormGroup>
                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.lunes} onChange={(event)=>{
                            const dias  = wizardData.dias
                            dias['lunes'] = event.target.checked
                            setWizardData({...wizardData, dias: dias})}} name="lunes" />} label="Lunes" />
                        
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.martes} onChange={(event)=>{
                            const dias  = wizardData.dias
                            dias['martes'] = event.target.checked
                            setWizardData({...wizardData, dias: dias})}} name="martes" />} label="Martes"/>

                        <FormControlLabel control={<Checkbox checked={wizardData.dias.miércoles} onChange={(event)=>{
                            const dias  = wizardData.dias
                            dias['miércoles'] = event.target.checked
                            setWizardData({...wizardData, dias: dias})}} name="miercoles" />} label="Miércoles"/>

                        <FormControlLabel control={<Checkbox checked={wizardData.dias.jueves} onChange={(event)=>{
                            const dias  = wizardData.dias
                            dias['jueves'] = event.target.checked
                            setWizardData({...wizardData, dias: dias})}} name="jueves" />} label="Jueves" />   

                        <FormControlLabel control={<Checkbox checked={wizardData.dias.viernes} onChange={(event)=>{
                            const dias  = wizardData.dias
                            dias['viernes'] = event.target.checked
                            setWizardData({...wizardData, dias: dias})}} name="viernes" />} label="Viernes" />       

                        <FormControlLabel control={<Checkbox checked={wizardData.dias.sábado} onChange={(event)=>{
                            const dias  = wizardData.dias
                            dias['sábado'] = event.target.checked
                            setWizardData({...wizardData, dias: dias})}} name="sabado" />} label="Sábado" />        

                        <FormControlLabel control={<Checkbox checked={wizardData.dias.domingo} onChange={(event)=>{
                            const dias  = wizardData.dias
                            dias['domingo'] = event.target.checked
                            setWizardData({...wizardData, dias: dias})}} name="domingo" />} label="Domingo" />                
                    </Grid>                            
                </FormGroup>
                <FormLabel component="legend">Período de generación</FormLabel>

                <Grid item xs={6} >
                    <TextField id="date" label="Fecha inicio" type="date" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaInicioPeriodo?wizardData.fechaInicioPeriodo:null}
                    onChange={(event, value) => {
                        setWizardData({...wizardData, fechaInicioPeriodo:event.target.value})}} fullWidth/>
                </Grid>
                <Grid item xs={6} >
                    <TextField id="date" label="Fecha fin" type="date" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaFinPeriodo?wizardData.fechaFinPeriodo:null} 
                    onChange={(event, value) => {
                        setWizardData({...wizardData, fechaFinPeriodo:event.target.value})}} fullWidth/>
                </Grid>
                <Grid item xs={12} >
                    <FormControlLabel control={<Checkbox checked={wizardData.generaPlantilla} onChange={(event)=>{
                        setWizardData({...wizardData, generaPlantilla: event.target.checked})}} name="generaPlantilla" />} label="Desea almacenar proceso de generación en una plantilla?" />
                </Grid>
            </Grid>

        </div>
    </React.Fragment>
  );
}

export default GAPeriodo;