import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Box, Drawer, CssBaseline, Toolbar, List, Typography, Divider, IconButton, Badge, Collapse} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { mainListItems, secondaryListItems, mainListSeguridad, mainListGestion } from '../Components/ListItems';
import Copyright from '../Components/Copyright'


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import {logout} from '../Services/AuthService'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faShieldAlt } from '@fortawesome/free-solid-svg-icons'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    //fontWeight: 'bold'

  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

}));

export const DashboardLayout = ({children, titulo, ...rest}) => {

  const classes                           = useStyles();
  const [open, setOpen]                   = useState(true);
  const [openSeguridad, setOpenSeguridad] = useState(false);
  const [openGestion, setOpenGestion]     = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleAceptar = async() => {
    await logout()
    window.location = '/home'
    setOpenDialog(false);
  };

  const handleClickSeguridad = () => {
    setOpenSeguridad(!openSeguridad);
  };

  const handleClickGestion = () => {
    setOpenGestion(!openGestion);
  };
  /* const logOut = () => {
    setOpenDialog(false);
  };   */

  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
          <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
              <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              {titulo}
          </Typography>
          <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
              </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleClickOpen}>
            <PowerSettingsNewIcon />
          </IconButton>
          </Toolbar>
      </AppBar>
      <Drawer variant="permanent" classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose), }} open={open}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
            <List>
              {mainListItems}
              <ListItem button onClick={handleClickGestion}>
                <ListItemIcon><FontAwesomeIcon icon={faTasks} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}} /></ListItemIcon>
                <ListItemText primary = "Gestión" />
                {openGestion ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openGestion} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {mainListGestion}
                </List>
              </Collapse>    


              <ListItem button onClick={handleClickSeguridad}>
                <ListItemIcon><FontAwesomeIcon icon={faShieldAlt} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/></ListItemIcon>
                <ListItemText primary = "Seguridad" />
                {openSeguridad ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openSeguridad} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {mainListSeguridad}
                </List>
              </Collapse>
              <Divider />              
              {secondaryListItems}
            </List>


      </Drawer>
      <main className={classes.content}>
          <div className={classes.appBarSpacer} />
              {children}
          <Box pt={2}>
              <Copyright />
          </Box>               
      </main>
      <Dialog
        open={openDialog}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Esta seguro que desea cerrar sesión?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAceptar} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>      
    </div>
  );
}

export default DashboardLayout;