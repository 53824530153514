import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
   palette:{
    primary: {
     main: '#d94043',
     contrastText: '#ffffff'
     // light: will be calculated from palette.primary.main,
     // dark: will be calculated from palette.primary.main,
     // contrastText: will be calculated to contrast with palette.primary.main
      },
   secondary: {
     light: '#34495e',
     main: '#34495e',
     // dark: will be calculated from palette.secondary.main,
     contrastText: '#ffffff',
   },
   rojo:{
     main: '#E65100',
     contrastText: '#ffffff',
   },
   // Used by `getContrastText()` to maximize the contrast between
   // the background and the text.
   tonalOffset: 0.2,
  }
    
   
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme ={ theme }>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
