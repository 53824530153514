import { apiPath } from "../Config/Config";

  export const login = () => {

    window.sessionStorage.setItem("auth", true)
  };
  

  export const isAuthenticated = () => {
    const isAuthenticated = window.sessionStorage.getItem("auth")
    if (isAuthenticated === null || isAuthenticated === false) {
      return false;
    } else {
      return true;
    } 
  };
  

  export const logout = () => {
    if (window.sessionStorage.getItem("auth") != null) {
      deleteTokenUser()
      window.sessionStorage.clear()
      window.localStorage.clear() 
    }
  };

  export async function deleteTokenUser (){

    try{
      const user    = window.localStorage.getItem('user')
      const baseUrl = apiPath+'users/me/logout'

      await fetch(baseUrl, 
          { method: 'post',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': window.localStorage.getItem('token'),
          },
          body: user
          }).then(response => response.json()).then(responsejson => console.log(responsejson))
    }catch(e){
        console.log(e);
    }
  }