import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle, FormControl,
  InputLabel, FormHelperText, Grid, FormControlLabel, Select, TablePagination, Divider, DialogContentText,
  Typography} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import {Create, Delete, Search, Add, Image} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import ImageUploading from "react-images-uploading";
import {apiPath, timezone} from '../Config/Config'
import moment from 'moment'
import guid from 'guid'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15
  }, 
  tablerow: {
    height: 33
  },
  paper:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop:20,
    paddingBottom:20,
    marginBottom: 15
  },
  buttonFilter:{
    marginTop:10
  },  
});

function Clases() {
  const classes = useStyles();
  // + Grid
    const [data, setData] = useState([])
  // -

  // + Formulario Clase
    const initialValueClase             = {
      _id           : '',
      fechaCreacion : moment().format('YYYY-MM-DDTHH:MM'),      
      titulo        : '',
      duracion      : 0,
      descripcion   : '',
      base64Imagen  : null,
      urlImagen     : '',
      activa        : true,
      bajaLogica    : false
    }

    const initialValueFilters           = {
      titulo      : '',
      duracion    : '',
      activa      : true,
      bajaLogica  : false
    } 

    const initialDialogError            = {
      titulo      : false,  
      descripcion : false,
      base64Imagen: false
  }

    const [clase, setClase]             = useState(initialValueClase)
    const [filters, setFilters]         = useState(initialValueFilters)
    const [dialogError, setDialogError] = useState(initialDialogError)
    const [open, setOpen]               = useState(false)
    const [mode, setMode]               = useState('')
    const [images, setImages]           = useState([]);    
  // -

  const onChange = (imageList, addUpdateIndex) => {
      if (imageList.length > 0){
          setImages(imageList);
          setClase({...clase, base64Imagen: imageList})  
          setDialogError({...dialogError, base64Imagen: false})
      } else{
          setImages(null);
          setClase({...clase, base64Imagen: null})
          setDialogError({...dialogError, base64Imagen: true})
      }
  };

  // + Function Formulario
  const handleClickOpen = (modo, row) => {
    setMode(modo)   
    setDialogError(initialDialogError)    
    if (modo === 'INS')
    {

      setClase(initialValueClase)
      setImages([])
    }else{
      setClase(row)
      setImages(row.base64Imagen)  
    }
    setOpen(true);
  };

  const handleClose = async(modo, validoOK) => {
    switch (modo) {
      case 'INS':
        if (validoOK){
          await agregarClase()
          setOpen(false);
        }
        break
        case 'UPD':
          if (validoOK){
            await modificarClase()
            setOpen(false);
          }
          break        
      case 'DLT':
        await eliminarClase()
        setOpen(false);
        break
      default:
        setOpen(false);
        break
    }
  };
  // -

  // + Function Acciones.
  async function getClases(){
    try{
      const baseUrl = apiPath+'clasesAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
            body:JSON.stringify({
            titulo    : filters.titulo,
            duracion  : filters.duracion,
            activa    : filters.activa,
            bajaLogica: filters.bajaLogica

          })  
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setData(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }  

  async function agregarClase(){

    try{  
      const ubicacion     = 'public/clases/'
      const nombreArchivo = 'Clase_' + guid.raw() + '.jpg'
      var Resultado       = ''
      Resultado           = await uploadImagen(ubicacion, nombreArchivo)

      if (Resultado = 'OK'){

        const baseUrl = apiPath+'clases'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
              fechaCreacion : moment(clase.fechaCreacion, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),              
              titulo        : clase.titulo,
              descripcion   : clase.descripcion,
              duracion      : clase.duracion,
              urlImagen     : 'clases/' + nombreArchivo,
              base64Imagen  : images,
              activa        : clase.activa,
              bajaLogica    : clase.bajaLogica
            })
        }).then(response => response.json())
        .then(responsejson=>{
            if(responsejson){
              let dataAux = data
              dataAux.push(responsejson.clase)
              setData(dataAux)
            }
          })  
        }
        //else
          // Se ha producido un error al subir una imagen
    }catch(error){
      console.log(error)
    } 

  }

  async function modificarClase(){
    try{
      const ubicacion     = 'public/clases/'
      var nombreArchivo   = clase.urlImagen.replace("clases/", "")
      var Resultado       = 'OK'

      if (images.length > 0 )
          Resultado = await uploadImagen(ubicacion, nombreArchivo)

      if(Resultado === 'OK'){
        const baseUrl = apiPath+'updateClase'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
              _id           : clase._id,
              titulo        : clase.titulo,
              duracion      : clase.duracion,
              base64Imagen  : images,
              activa        : clase.activa            
            })
        }).then(response => response.json())
          .then(responsejson => {
            const updClase = responsejson.clase
            let dataAux     = data

            dataAux.forEach(item => {
              if(item._id === updClase._id){
                item.titulo       = updClase.titulo
                item.duracion     = updClase.duracion
                item.descripcion  = updClase.descripcion
                item.base64Imagen = updClase.base64Imagen
                item.activa       = updClase.activa
                return item
              }
            })
          })
        }          
      }catch(error){
        console.log(error)
      }
  }

  async function eliminarClase(){
    try{
      const baseUrl = apiPath+'deleteClase'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            _id : clase._id
          })          
      }).then(response =>response.json())
        .then(responsejson => {
          if (responsejson.Resultado === 'OK'){
            let dataAux = data
            dataAux.forEach(item => {
              if(item._id === clase._id){
                var i = dataAux.indexOf(item)
                i !== -1 && dataAux.splice( i, 1 )
                return item
              }
            })
          }
        })
    }catch(error){
      console.log(error)
    }        
  }

  async function uploadImagen(ubicacion, nombreArchivo){
    try{  
        var Resultado = null
        const baseUrl = apiPath+'uploadImagen'

        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
                imagenBase64  : clase.base64Imagen,
                ubicacion     : ubicacion,
                nombreArchivo : nombreArchivo
            })
        })
        .then(response => response.json())
        .then(responsejson=>{
            if(responsejson){
                Resultado = responsejson.Resultado
            }
        })  
        return Resultado
    }catch(error){
      console.log(error)
    }         
}

  useEffect( ()=>{
    getClases()
    return () => {
      setData([])
      //clearInterval(intervalRef.current);
    }; 
  
  },[])

// + Paginado
const [rowsPerPage, setRowsPerPage] = React.useState(8);
const [page, setPage]               = React.useState(0);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
// -

  return (
    <div className = {classes.main}>
    <CssBaseline />
    <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title"  maxWidth='md' fullWidth>
      <DialogTitle id="form-dialog-title">
      {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Clase</DialogTitle>
        <DialogContent>
        <DialogContentText style={{color:'red'}}>
          { mode==='DLT' ? 
            ('¿Esta seguro que quiere eliminar la clase?')
            :
            ('')
          }
        </DialogContentText>           
        <Grid container spacing={3}>
          <Grid item container spacing={3} xs={7}>
            <Grid item  xs={12}>
              <TextField required id="titulo" name="titulo" label="Título" value={clase.titulo} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                onChange={event => {
                  setClase({...clase, titulo : event.target.value})
                  if (event.target.value)
                    setDialogError({...dialogError, titulo : false})
                  else
                    setDialogError({...dialogError, titulo : true})                
                }} fullWidth autoComplete="given-name" error={dialogError.titulo} helperText={dialogError.titulo?"Debe ingresar un titulo.":""}/>
            </Grid>
            <Grid item  xs={6}>
              <FormControl required className={classes.formControl}>
                  <InputLabel htmlFor="duracion-native-required" >Duración</InputLabel>
                    <Select native value={clase.duracion} onChange={event => {
                      setClase({...clase, duracion : event.target.value})
                    }} name="duracion"
                      inputProps={{ id: 'duracion-native-required' }} disabled={(mode==='DSP'||mode==='DLT')?true:false}>

                      <option value={0}> Libre</option>
                      <option value={45}> 45 min.</option>
                      <option value={50}> 50 min.</option>
                      <option value={55}> 55 min.</option>
                      <option value={60}> 60 min.</option>
                    </Select>
                    <FormHelperText style={{width:'15vh'}}></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item  xs={6}>         
                <FormControlLabel style={{marginTop:10}} control={<Checkbox color="secondary" name="activa"  checked={clase.activa ? true : false} 
                  onChange={event => {
                    setClase({...clase, activa : event.target.checked})
                    }}/>} label="Activa" value={clase.activa} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
              </Grid>
              <Grid item xs={12}>
                <TextField required id="descripcion" value={clase.descripcion} name="descripcion" label="Descripción" disabled={(mode==='DSP'||mode==='DLT')?true:false}
                onChange={event => {
                  setClase({...clase, descripcion : event.target.value})
                  if (event.target.value)
                    setDialogError({...dialogError, descripcion : false})
                  else
                    setDialogError({...dialogError, descripcion : true})  
                  }} fullWidth autoComplete="shipping address-line1" multiline rows={6} error={dialogError.titulo} helperText={dialogError.descripcion?"Debe ingresar una descripción.":""}/>
              </Grid>              
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={5} direction='row'>
                <FormControl /*error={dialogError.base64Imagen}*/>
                    <ImageUploading multiple={false} value={images} onChange={onChange} dataURLKey="data_url">
                        {({imageList, onImageUpload, onImageRemove, dragProps}) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image.data_url} alt="" width="200" />
                                {(mode==='DSP' || mode==='DLT')?
                            (<div></div>):(<Button color="secondary" style={{marginLeft:50}} startIcon={<Delete />} onClick={() => onImageRemove(index)}>Eliminar</Button>)}
                            </div>
                            ))}
                            {(mode==='DSP' || mode==='DLT')?
                            (<div></div>):(<Button startIcon={<Image />} style={{marginTop:20}} onClick={onImageUpload} {...dragProps} variant="contained" color="primary">Añadir / Modificar</Button>)}
                        </div>
                        )}
                    </ImageUploading>
                    {/*(dialogError.base64Imagen)?(<FormHelperText>Debe seleccionar una imagen</FormHelperText>):<FormHelperText></FormHelperText>*/}
                </FormControl>                    
            </Grid>                    
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
      { (mode==='DSP' )?
        ( <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button> )
        :
        (<div>
          <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button>          
          <Button onClick={()=>{
                    setDialogError({...dialogError, titulo: (clase.titulo === ''), 
                    descripcion : (clase.descripcion === ''), 
                    base64Imagen : (clase.base64Imagen === null)})
            var validoOK = !((clase.titulo === '')||(clase.descripcion === '')||(clase.base64Imagen === null))
            handleClose(mode, validoOK)
          }} color="primary" >
            {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
          </Button>
        </div>)
      }
      </DialogActions>
    </Dialog>

    <TableContainer className={classes.paper} component={Paper}>
        <Grid container spacing={4} >
            <Grid item xs={3}>
              <TextField required id="titulo" value={filters.titulo} name="titulo" label="Titulo" 
              onChange={event => setFilters({...filters, titulo : event.target.value})} fullWidth autoComplete="shipping address-line1" multiline rows={1}/>
            </Grid>
            <Grid item xs={2} >
              <FormControl required className={classes.formControl} style={{width:150}}>
                  <InputLabel htmlFor="duracion-native-required" >Duración</InputLabel>
                  <Select native value={filters.duracion} onChange={event => setFilters({...filters, duracion : event.target.value})} name="duracion"
                    inputProps={{ id: 'duracion-native-required' }}>
                    <option aria-label="None" value="" />
                    <option value={45}>45 min.</option>
                    <option value={50}>50 min.</option>
                    <option value={55}>55 min.</option>
                    <option value={60}>60 min.</option>
                  </Select>
                  <FormHelperText style={{width:'15vh'}}></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel style={{marginTop:10, marginLeft:10}} control={<Checkbox color="secondary" name="activa" value={filters.activa} checked={filters.activa ? true : false} />}
                    label="Activa" value={filters.activa} onChange={event => setFilters({...filters, activa : event.target.checked})}/>
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel style={{marginTop:10, marginLeft:10}} control={<Checkbox color="secondary" name="eliminado" value={filters.bajaLogica} checked={filters.bajaLogica ? true : false} />}
                    label="Eliminados" value={filters.bajaLogica} 
                    onChange={event => setFilters({...filters, bajaLogica : event.target.checked})}/>
            </Grid>        
            <Grid item xs={2}>
              <div>
                <Button  variant="contained"  color="primary" className={classes.buttonFilter}  startIcon={<FontAwesomeIcon icon={faSearch} fixedWidth style={{padding:1.5}}/>} align="right"
                      onClick={()=>getClases()}>Buscar</Button>
              </div>
            </Grid>
        </Grid>      
    </TableContainer>
    <TableContainer className={classes.paper} component={Paper}>
    <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>handleClickOpen('INS', '')} >
          Agregar
        </Button>
      </div>
      <Divider/>
      <Table className={classes.table} aria-label="simple table" size='small'>
        <TableHead style={{backgroundColor:"#34495e"}}>
          <TableRow >
          <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>            
              <TableCell><Typography style={{color:'white'}}>Clase</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Duración</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Activa?</Typography></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>

              <TableCell align="left" >
                <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                  <Search />
                </IconButton>                
              </TableCell>
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                  <Create />
                </IconButton>                
              </TableCell>                            
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.titulo}
              </TableCell>
              <TableCell align="left">{row.duracion > 0 ? row.duracion +' min.':'Sin limite'}</TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={row.activa ? true : false}
                  className={classes.checkbox}
                  //inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}

export default Clases;