import React, { createContext, useState, useContext } from 'react';
import moment from 'moment'
 
const WizardContext = createContext();

export default function WizardProvider({ children }) {

    const initialWizardData = {
        dias        : {lunes: true,
            martes: true,
            miércoles: true,
            jueves: true,
            viernes:true,
            sábado:false,
            domingo:false},       
        fechaInicioPeriodo  : '',
        fechaFinPeriodo     : '',
        activa: false,
        cuposDisponibles: 20,
        fechaHoraInicio: '',
        fechaHoraFin: '',
        _claseID: {titulo:""},
        _userID: {name:"",lastName:""},
        _ubicacionID: {nombre:""},
        fechaCreacionAgenda: moment().format(),
        generaPlantilla: false,
        nombrePlantilla: ''

    }    

    const [wizardData, setWizardData] = useState(initialWizardData)

    return(
        <WizardContext.Provider
            value = {{
                wizardData,
                setWizardData
                }}
        >
            {children}
        </WizardContext.Provider>
    )
}

export function useWizard(){
    const context = useContext(WizardContext)
    const { wizardData, setWizardData} = context
    return { wizardData, setWizardData }
}