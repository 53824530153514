import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as AuthService from "../Services/AuthService";
import DashboardLayout from '../layouts/DashboardLayout'
import WizardProvider from '../Components/WizardContext'

const ProtectedRoute = ({ component: Component, titulo, ...rest }) => {
  return (

    <Route {...rest} render={ props => (

        AuthService.isAuthenticated() === true ? (
          
          <DashboardLayout titulo={titulo}>
            <WizardProvider >
              <Component  />
            </WizardProvider>
          </DashboardLayout>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location }
            }}
          />
        )
      )}
    />
  );
};

export default ProtectedRoute;
