import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle, 
  Grid, FormControlLabel, TablePagination, Divider, DialogContentText, Typography} from '@material-ui/core';

import NumericInput from 'react-numeric-input';
import IconButton from '@material-ui/core/IconButton';

import { Create, Delete, Search, Add, Palette} from '@material-ui/icons';
import { apiPath, timezone } from '../Config/Config'

import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

//import axios from 'axios'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
    
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15,

  }, 
  tablerow: {
    height: 33
  },
  paper:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop:20,
    paddingBottom:20,
    marginBottom: 15
  }
});

function Agendas () {
  const classes = useStyles();
  // + Grid
    const [data, setData]           = useState([])
  // -

  // + Formulario Agenda
    const [open, setOpen]     = useState(false)
    const [mode, setMode]     = useState('')
    const initialValueAgenda  = {
        _id : '',
        fechaHoraInicio : null, //moment().format(),
        fechaHoraFin : null, //moment().format(),
        fechaCreacionAgenda: moment().format(),
        _userID : {name:"", lastName:""},
        _claseID : {titulo:""},
        _ubicacionID : {nombre:""},
        cuposDisponibles: 20,
        activa : false,
    }
    const [agenda, setAgenda]           = useState(initialValueAgenda)
    const [clases, setClases]           = useState([])
    const [profesores, setProfesores]   = useState([])
    const [ubicaciones, setUbicaciones] = useState([])
  // - Formulario Agenda

  // + Filtros Agenda
    const [fechaDesdeFiltro, setFechaDesdeFiltro]   = useState(moment().format('YYYY-MM-DD HH:MM'))
    const [fechaHastaFiltro, setFechaHastaFiltro]   = useState(null)
    const [claseFiltro, setClaseFiltro]             = useState(null)
    const [profesorFiltro, setProfesorFiltro]       = useState(null)
    const [ubicacionFiltro, setUbicacionFiltro]     = useState(null)
    const [activaFiltro, setActivaFiltro]           = useState(true)

    const [clasesCombo, setClasesCombo]             = useState([])
    const [profesoresCombo, setProfesoresCombo]     = useState([])
    const [ubicacionesCombo, setUbicacionesCombo]   = useState([])
  // - Filtros Agenda




  // + Function Formulario
  const handleClickOpen = (modo, agenda) => {
    setMode(modo)
    if (modo === 'INS')
    {
      setAgenda(initialValueAgenda)
    }else{
      setAgenda(agenda)
    }
    setOpen(true);
  };

  const handleClose = async(modo) => {
    switch (modo) {
      case 'INS':
        await agregarAgenda()
        setOpen(false);
        break;
      case 'DLT':
        await eliminarAgenda()
        setOpen(false);
        break;
      case 'UPD':
        await modificarAgenda()
        setOpen(false);
        break
      default:
        setOpen(false);
        break
    }
  };
  // -

  async function getAgendas(){
    try{
      const baseUrl = apiPath+'agendasAll'

      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            fechaDesdeFiltro: moment(fechaDesdeFiltro, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
            fechaHastaFiltro: moment(fechaHastaFiltro, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
            claseFiltro     : claseFiltro?claseFiltro._id:null,
            profesorFiltro  : profesorFiltro?profesorFiltro._id:null,
            ubicacionFiltro : ubicacionFiltro?ubicacionFiltro._id:null,
            activaFiltro    : activaFiltro
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setData(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  async function getClases(){
    try{
      const baseUrl = apiPath+'clasesAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            activa : true,
            bajaLogica : false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setClases(responsejson)
        setClasesCombo(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  async function getProfesores(){
    try{
      const baseUrl = apiPath+'usersAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            isProfesor  : true,
            activo      : true,
            bajaLogica  : false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setProfesores(responsejson)
        setProfesoresCombo(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  async function getUbicaciones(){
    try{
      const baseUrl = apiPath+'ubicacionAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            activa : true,
            bajaLogica : false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setUbicaciones(responsejson)
        setUbicacionesCombo(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  // + Function Acciones.
  const agregarAgenda = async() => {
    var dataAux = null
      try{  
        const baseUrl = apiPath+'agenda'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
              fechaCreacionAgenda : moment(agenda.fechaCreacionAgenda, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
              _claseID            : agenda._claseID._id,
              _userID             : agenda._userID._id,
              _ubicacionID        : agenda._ubicacionID._id,
              fechaHoraInicio     : moment(agenda.fechaHoraInicio, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
              fechaHoraFin        : moment(agenda.fechaHoraFin, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
              cuposDisponibles    : agenda.cuposDisponibles,
              activa              : agenda.activa
            })
        }).then(response => response.json())
        .then((responsejson)=>{
            if(responsejson){
              dataAux = data
              dataAux.push(responsejson)
              setData(dataAux)
            }
          })
    }catch(error){
      console.log(error)
    }
  }

  const modificarAgenda = async() => {
     try{
      const baseUrl = apiPath+'updateAgenda'

      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            _id               : agenda._id,
            _userID           : agenda._userID._id,
            _claseID          : agenda._claseID._id,
            _ubicacionID      : agenda._ubicacionID._id,
            cuposDisponibles  : agenda.cuposDisponibles,
            activa            : agenda.activa,
            fechaHoraInicio   : moment(agenda.fechaHoraInicio, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
            fechaHoraFin      : moment(agenda.fechaHoraFin, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString()
          })
      }).then(response => response.json())
        .then(responsejson => {
          const agendaUpdate  = responsejson
          let dataAux         = data
          dataAux.forEach(item => {
            if(item._id === agendaUpdate._id){
              item.fechaHoraInicio  = agendaUpdate.fechaHoraInicio
              item.fechaHoraFin     = agendaUpdate.fechaHoraFin
              item.cuposDisponibles = agendaUpdate.cuposDisponibles
              item.activa           = agendaUpdate.activa
              item._userID          = agendaUpdate._userID
              item._ubicacionID     = agendaUpdate._ubicacionID
              item._claseID         = agendaUpdate._claseID
              return item
            }
          })
        })
    }catch(error){
      console.log(error)
    } 
  }

  const eliminarAgenda = async() => {
     try{
      const baseUrl = apiPath+'deleteAgenda'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            _id : agenda._id
          })          
      }).then(response =>response.json())
        .then(responsejson => {
          if (responsejson.Resultado === 'OK'){
            let dataAux = data
            dataAux.forEach(agendaAux => {
              if(agendaAux._id === agenda._id){
                var i = dataAux.indexOf(agendaAux)
                i !== -1 && dataAux.splice( i, 1 )
                return agendaAux
              }
            })
          }
        })
    }catch(error){
      console.log(error)
    }     
  }

  // - Function Acciones.

  useEffect( ()=>{
    getAgendas()
    getClases()
    getProfesores()
    getUbicaciones()
    return () => {
      setData([])
      setClases([])
      setProfesores([])
      setUbicaciones([])
      setClasesCombo([])
      setProfesoresCombo([])
      setUbicacionesCombo([])
      setActivaFiltro([])
    }; 
  },[])

// + Paginado
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [page, setPage]               = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
// - Paginado

  return (
    <div className = {classes.main}>
    <CssBaseline />
    <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" >{mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Agenda</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color:'red'}}>
          { mode==='DLT' ? 
            ('¿Esta seguro que quiere eliminar la agenda?')
            :
            ('')
          }
        </DialogContentText>  
        <Grid container spacing={3}>
          <Grid item xs={6}>
              <Autocomplete id="combo-box-demo" options={clases} getOptionLabel={(option) => option.titulo} style={{ width: 250 }} 
              disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Clase" variant="outlined" />} 
              value={agenda._claseID} 
              onChange={(event, value) => {setAgenda({...agenda, _claseID : value})}} autoComplete = {false}/>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Typography variant="caption">Duración</Typography>
              <Typography variant="body1">{agenda._claseID.duracion ? agenda._claseID.duracion+' min.': ''}</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
              <Autocomplete id="combo-box-demo" options={profesores} getOptionLabel={(option) => option.lastName} style={{ width: 250 }} 
              disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Profesor" variant="outlined" />}
              onChange={(event, value) =>{setAgenda({...agenda, _userID : value})}} value={agenda._userID}/>
          </Grid>
          <Grid item xs={12}>
              <Autocomplete id="combo-box-demo" options={ubicaciones} getOptionLabel={(option) => option.nombre} style={{ width: 250 }} 
              disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />} 
              onChange={(event, value) =>{setAgenda({...agenda, _ubicacionID : value})}} value={agenda._ubicacionID}/>
          </Grid>          
          <Grid item xs={6} >
              <TextField id="datetime-local" label="Fecha / Hora inicio" type="datetime-local" className={classes.textField}
              InputLabelProps={{ shrink: true, }} value={agenda.fechaHoraInicio?agenda.fechaHoraInicio.substr(0, 16):null} disabled={(mode==='DSP'||mode==='DLT')?true:false}
              onChange={(event) => {
                const fechaHoraFin = moment(event.target.value).add(parseInt(agenda._claseID.duracion), 'm').format().substring(0, 16)
                setAgenda({...agenda, fechaHoraInicio : event.target.value, fechaHoraFin : fechaHoraFin})
              }} fullWidth/>
          </Grid>

          <Grid item xs={6} >
              <TextField id="datetime-local" label="Fecha / Hora fin" type="datetime-local" className={classes.textField}
              InputLabelProps={{ shrink: true, }} value={agenda.fechaHoraFin?agenda.fechaHoraFin.substr(0, 16):null} disabled
 fullWidth/>
          </Grid>


          <Grid item xs={6}>
              <div>
                <Typography variant="caption" /*color="primary"*/ gutterBottom disabled={(mode==='DSP'||mode==='DLT')?true:false}>Cantidad de cupos</Typography>
                <NumericInput className="form-control" mobile min={10} max={150} step={1} value={agenda.cuposDisponibles} autoCorrect="on" size={25} 
                disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={(event, value) => setAgenda({...agenda, cuposDisponibles : value})}/> 
              </div>
          </Grid>                 
          <Grid item xs={6}>
              <FormControlLabel control={<Checkbox color="secondary" name="activa" value={agenda.activa} checked={agenda.activa ? true : false} />}
               label="Activa" value={agenda.activa} disabled={(mode==='DSP'||mode==='DLT')?true:false}
               onChange={event => setAgenda({...agenda, activa : event.target.checked})}/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
      { (mode==='DSP' )?
        ( <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button> )
        :
        (<div>
          <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button>          
          <Button onClick={()=>handleClose(mode)} color="primary" >
            {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
          </Button>
        </div>)
      }
      </DialogActions>
    </Dialog>


    <TableContainer className={classes.paper} component={Paper}>
    <Grid container spacing={4} >
      <Grid item xs={4} >
          <TextField id="datetime-local" label="Fecha / Hora Desde" type="datetime-local" style={{ width: 200 }}
          InputLabelProps={{ shrink: true, }} value={fechaDesdeFiltro?fechaDesdeFiltro.substr(0, 16):null} 
          onChange={(event) => { setFechaDesdeFiltro(event.target.value) }} fullWidth/>
      </Grid>
      <Grid item xs={4} >
          <TextField id="datetime-local" label="Fecha / Hora Hasta" type="datetime-local" style={{ width: 200 }} 
          InputLabelProps={{ shrink: true, }} value={fechaHastaFiltro?fechaHastaFiltro.substr(0, 16):null} 
          onChange={(event) => { setFechaHastaFiltro(event.target.value) }} fullWidth/>
      </Grid>      
      <Grid item xs={4}>
          <Autocomplete id="combo-box-demo" options={clasesCombo} getOptionLabel={(option) => option.titulo} style={{ width: 200 }} 
           renderInput={(params) => <TextField {...params} label="Clase" variant="outlined" />} 
          value={claseFiltro} 
          onChange={(event, value) => {setClaseFiltro(value)}} autoComplete = {false}/>
      </Grid>
      <Grid item xs={4}>
          <Autocomplete id="combo-box-demo" options={profesoresCombo} getOptionLabel={(option) => option.lastName} style={{ width: 200 }} 
           renderInput={(params) => <TextField {...params} label="Profesor" variant="outlined" />}
          onChange={(event, value) =>{setProfesorFiltro(value)}} value={profesorFiltro}/>
      </Grid>
      <Grid item xs={4}>
          <Autocomplete id="combo-box-demo" options={ubicacionesCombo} getOptionLabel={(option) => option.nombre} style={{ width: 200 }} 
          renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />} 
          onChange={(event, value) =>{setUbicacionFiltro(value)}} value={ubicacionFiltro}/>
      </Grid>
      <Grid item xs={2}>
          <FormControlLabel style={{marginTop:8, marginLeft:10}} control={<Checkbox color="secondary" name="activa" value={activaFiltro} checked={activaFiltro ? true : false} />}
              label="Activa" value={activaFiltro} 
              onChange={event => {setActivaFiltro(event.target.checked)}}/>
      </Grid>          
      <Grid item xs={2}>
          <div>
            <Button  variant="contained"  color="primary" className={classes.button} startIcon={<FontAwesomeIcon icon={faSearch} /*size = '2x'*/ fixedWidth /*fixedheight*/ style={{padding:1.5}}/>} align="right"
            onClick={()=>getAgendas()}>Buscar</Button>
          </div>
      </Grid>
    </Grid>      
      

    </TableContainer>
    

    <TableContainer className={classes.paper} component={Paper}>
      <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>handleClickOpen('INS', '')}>Agregar</Button>
      </div>
      <Divider/>
      <Table className={classes.table} aria-label="simple table" size='small'>
        <TableHead style={{backgroundColor: '#34495e'}}> 
          <TableRow >
            <TableCell style={{width:'10px'}} align="left" ></TableCell>
            <TableCell style={{width:'10vh'}} align="left"></TableCell>
            <TableCell style={{width:'10vh'}} align="left"></TableCell>            
            <TableCell><Typography style={{color:'white'}}>Fecha / Hora</Typography></TableCell>
            <TableCell><Typography style={{color:'white'}}>Clase</Typography></TableCell>
            <TableCell align="left"><Typography style={{color:'white'}}>Profesor</Typography></TableCell>
            <TableCell align="left"><Typography style={{color:'white'}}>Lugar</Typography></TableCell>
            <TableCell align="left"><Typography style={{color:'white'}}>Activa?</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>
              <TableCell align="left" >
                <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                  <Search />
                </IconButton>                
              </TableCell>
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                  <Create />
                </IconButton>                
              </TableCell>                            
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.fechaHoraInicio.substr(8, 2) +'/'+ row.fechaHoraInicio.substr(5, 2)+
                '/'+row.fechaHoraInicio.substr(0, 4)+' - ' +row.fechaHoraInicio.substr(11, 5)}
              </TableCell>              
              <TableCell component="th" scope="row">
                {row._claseID.titulo}
              </TableCell>
              <TableCell align="left">
                {row._userID.lastName}
              </TableCell>
              <TableCell align="left">
                {(row._ubicacionID)?row._ubicacionID.nombre:""}
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={row.activa ? true : false}
                  className={classes.checkbox}/>
              </TableCell>              
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}

export default Agendas;

/* +' - ' +row.fechaHoraInicio.substr(11, 5) */

/* row.fechaHoraInicio.substr(8, 2) +'/'+ row.fechaHoraInicio.substr(5, 2)+
              '/'+  */