import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormLabel,
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle, FormGroup, IconButton,
  Grid, FormControlLabel, TablePagination, Divider, DialogContentText, Typography} from '@material-ui/core';

import {Create, Delete, Search, Add} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumericInput from 'react-numeric-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import {apiPath, timezone} from '../Config/Config'
import moment from 'moment'

//import axios from 'axios'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15
  }, 
  tablerow: {
    height: 33
  },
  paper:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop:20,
    paddingBottom:20,
    marginBottom: 15
  },
  buttonFilter:{
    marginTop:10
  },    
});

function Plantillas () {
    const classes           = useStyles();
  // + Grid
    const [data, setData]   = useState([])
    const [open, setOpen]   = useState(false)
    const [isLoading, setIsLoading] = useState(false)
  // -

  // + Formulario Plantilla

    const initialValuePlantilla = {
        dias        : {lunes: true,
            martes: true,
            miércoles: true,
            jueves: true,
            viernes:true,
            sábado:false,
            domingo:false},
        activa: true,
        bajaLogica : false,
        cuposDisponibles: 20,
        horaInicio: null,
        horaFin: null,
        _claseID: '',
        _userID: '',
        _ubicacionID: '',
        fechaCreacionAgenda: moment().format('YYYY-MM-DDTHH:MM'),
    }
    const [plantilla, setPlantilla]     = useState(initialValuePlantilla)

    const initialValueFilters           = {
      _userID       : '',
      _ubicacionID  : '',
      _claseID      : '',
      dias          : {
          lunes     : false,
          martes    : false,
          miércoles : false,
          jueves    : false,
          viernes   :false,
          sábado    :false,
          domingo   :false
        },      
      activa        : true,
      bajaLogica    : false
    } 
    const [filters, setFilters]         = useState(initialValueFilters)    

    const initialDialogError            = {
      _claseID      : false,  
      _userID       : false,
      _ubicacionID  : false,
      horaInicio    : false
    }    
    const [dialogError, setDialogError] = useState(initialDialogError)

    const [mode, setMode]               = useState('')

    const [ubicaciones, setUbicaciones] = useState([])
    const [profesores, setProfesores]   = useState([])
    const [clases, setClases]           = useState([])

  // -

  // + Function Formulario
  const handleClickOpen = (modo, row) => {
    setMode(modo)
    setDialogError(initialDialogError)    
    if (modo === 'INS')
    {
      setPlantilla(initialValuePlantilla)
    }else{
      setPlantilla(row)
    }
    setOpen(true);
  };

  const handleClose = async(modo) => {
    switch (modo) {
      case 'INS':
          await agregarPlantilla()
          setOpen(false);
        break;
      case 'DLT':
        await eliminarPlantilla()
        setOpen(false);
        break;
      case 'UPD':
        await modificarPlantilla()
        setOpen(false);
        break
      default:
        setOpen(false);
        break
    }
  };
  // -

  async function getPlantillas(){
    try{
      const baseUrl = apiPath+'plantillasAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            _ubicacionID  : filters._ubicacionID?filters._ubicacionID._id:"",
            _userID       : filters._userID?filters._userID._id:"",
            _claseID      : filters._claseID?filters._claseID._id:"",
            dias          : filters.dias,
            activa        : filters.activa,        
            bajaLogica    : filters.bajaLogica
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setData(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }  

  async function getClases(){
    try{
      const baseUrl = apiPath+'clasesAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body:JSON.stringify({
            activa      : true,
            bajaLogica  : false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setClases(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  async function getProfesores(){
    try{
      const baseUrl = apiPath+'usersFiltro'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            activo : true,
            bajaLogica : false,
            isProfesor : true
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setProfesores(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  async function getUbicaciones(){
    try{
      const baseUrl = apiPath+'ubicacionesFiltro'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            activa : true,
            bajaLogica : false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setUbicaciones(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  // + Function Acciones.
  const agregarPlantilla = async() => {
    try{
      const baseUrl   = apiPath+'plantilla'
      const fechaNow  = moment().format('YYYY-MM-DD hh:mm')       
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            fechaCreacion     : moment(fechaNow, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
            _claseID          : plantilla._claseID._id,
            _userID           : plantilla._userID._id,
            _ubicacionID      : plantilla._ubicacionID._id,
            dias              : plantilla.dias,
            horaInicio        : plantilla.horaInicio,
            horaFin           : plantilla.horaFin,
            cuposDisponibles  : plantilla.cuposDisponibles,
            bajaLogica        : plantilla.bajaLogica,
            activa            : plantilla.activa,
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        console.log(responsejson)
        if(responsejson){
          let dataAux = data
          dataAux.push(responsejson)
          setData(dataAux)
          console.log(dataAux)
        }        
      })
    }catch(error){
      console.log(error)
    }
  }

  const modificarPlantilla = async() => {
    try{
      setIsLoading(true)
      const baseUrl = apiPath+'updatePlantilla'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            _id               : plantilla._id,
            _userID           : plantilla._userID._id,
            _ubicacionID      : plantilla._ubicacionID._id,
            _claseID          : plantilla._claseID._id,
            cuposDisponibles  : plantilla.cuposDisponibles,
            dias              : plantilla.dias,
            horaInicio        : plantilla.horaInicio,
            horaFin           : plantilla.horaFin,
            activa            : plantilla.activa
          })
      }).then(response => response.json())
        .then(responsejson => {
          const plantillaUpdate = responsejson
          let dataAux           = data
          dataAux.forEach(item => 
            {
            if (item._id === plantillaUpdate._id) {
              item._userID          = plantillaUpdate._userID
              item._ubicacionID     = plantillaUpdate._ubicacionID
              item._claseID         = plantillaUpdate._claseID
              item.cuposDisponibles = plantillaUpdate.cuposDisponibles
              item.dias             = plantillaUpdate.dias
              item.horaInicio       = plantillaUpdate.horaInicio
              item.horaFin          = plantillaUpdate.horaFin
              item.activa           = plantillaUpdate.activa
              return item
            }
          })
        }).then(setIsLoading(false))
    }catch(error){
      console.log(error)
    }
  }

  const eliminarPlantilla = async() => {
    try{
      const baseUrl = apiPath+'deletePlantilla'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            _id : plantilla._id
          })          
      }).then(response =>response.json())
        .then(responsejson => {
          if (responsejson.Resultado === 'OK'){
            let dataAux = data
            dataAux.forEach(item => {
              if(item._id === plantilla._id){
                var i = dataAux.indexOf(item)
                i !== -1 && dataAux.splice( i, 1 )
                return item
              }
            })
          }
        })
    }catch(error){
      console.log(error)
    }    
  }

  // - Function Acciones.

  useEffect( ()=>{
    getPlantillas()
    getProfesores()
    getClases()
    getUbicaciones()
    return () => {
      setData([])
      setProfesores([])
      setClases([])
      setUbicaciones([])
    }; 
  },[])

// + Paginado
const [rowsPerPage, setRowsPerPage] = React.useState(8);
const [page, setPage]               = React.useState(0);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
// - Paginado

  return (
    <div className = {classes.main}>
    <CssBaseline />
    <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
      {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Plantilla</DialogTitle>
        <DialogContent>
        <DialogContentText style={{color:'red'}}>
          { mode==='DLT' ? 
            ('¿Esta seguro que quiere eliminar la plantilla?')
            :
            ('')
          }
        </DialogContentText>           
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Autocomplete id="combo-box-demo" options={clases} getOptionLabel={(option) => option.titulo} style={{ width: 250 }} 
                disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Clase" variant="outlined" />} 
                value={plantilla._claseID?plantilla._claseID:{titulo:""}} error={dialogError._claseID} helperText={dialogError._claseID?"Debe seleccionar una clase.":""}
                onChange={(event, value) => {
                    setPlantilla({...plantilla, _claseID : value})
                    if(value === '')
                      setDialogError({...dialogError, _claseID:true})
                    else
                      setDialogError({...dialogError, _claseID:false})
                }}                     
                   autoComplete = {false}/>
            </Grid>
            <Grid item xs={6}>
                <div>
                    <Typography variant="caption">Duración</Typography>
                    <Typography variant="body1">{plantilla._claseID ? plantilla._claseID.duracion+' min.': ''}</Typography>
                </div>
            </Grid>
            <Grid item xs={6} >
                <TextField id="time" label="Hora inicio" type="time" className={classes.textField}
                InputLabelProps={{ shrink: true, }} value={plantilla.horaInicio?plantilla.horaInicio:null/* agenda.fechaHoraInicio?agenda.fechaHoraInicio:null*/} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                onChange={(event) => {
                    const value = event.target.value
                    const fechaAux      = moment().minute(parseInt(value.substr(3, 2))).hour(parseInt(value.substr(0, 2))).format()
                    const horaFin  = moment(fechaAux).add(parseInt(plantilla._claseID.duracion), 'm').format().substr(11, 5)
                    setPlantilla({...plantilla, horaInicio:value, horaFin:horaFin})
                    if(value === null)
                      setDialogError({...dialogError, _userID:true})
                    else
                      setDialogError({...dialogError, _userID:false})                    
                    }} fullWidth error={dialogError.horaInicio} helperText={dialogError.horaInicio?"Debe ingresar una hora de inicio.":""}/>
            </Grid>
            <Grid item xs={6} >
                <TextField id="time" label="Hora fin" type="time" className={classes.textField}
                InputLabelProps={{ shrink: true, }} value={plantilla.horaFin?plantilla.horaFin:null/*agenda.fechaHoraFin?agenda.fechaHoraFin.substr(0, 16):null*/} disabled
                fullWidth/>
            </Grid>             

            <Grid item xs={6}>
                <Autocomplete id="combo-box-demo" options={profesores} getOptionLabel={(option) => option.lastName} style={{ width: 250 }} 
                disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Profesor" variant="outlined" />}
                onChange={(event, value) =>{
                  setPlantilla({...plantilla, _userID : value})
                  if(value === '')
                    setDialogError({...dialogError, _userID:true})
                  else
                    setDialogError({...dialogError, _userID:false})
                  }} error={dialogError._userID} helperText={dialogError._userID?"Debe seleccionar un profesor.":""} 
                  value={plantilla._userID?plantilla._userID:{lastName:""}}/>
            </Grid>
            <Grid item xs={6}>
                <Autocomplete id="combo-box-demo" options={ubicaciones} getOptionLabel={(option) => option.nombre} style={{ width: 250 }} 
                disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />} 
                onChange={(event, value) =>{
                  setPlantilla({...plantilla, _ubicacionID : value})
                  if(value === '')
                    setDialogError({...dialogError, _ubicacionID:true})
                  else
                    setDialogError({...dialogError, _ubicacionID:false})                  
                  }} error={dialogError._ubicacionID} helperText={dialogError._ubicacionID?"Debe seleccionar una ubicación.":""} 
                  value={plantilla._ubicacionID?plantilla._ubicacionID:{nombre:""}}/>
            </Grid>

            <FormLabel component="legend">Seleccione los días...</FormLabel>
            <FormGroup>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={plantilla.dias.lunes} onChange={(event)=>{
                        const dias  = plantilla.dias
                        dias['lunes'] = event.target.checked
                        setPlantilla({...plantilla, dias: dias})}} name="lunes" />} label="Lunes" disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
                    
                    <FormControlLabel control={<Checkbox checked={plantilla.dias.martes} onChange={(event)=>{
                        const dias  = plantilla.dias
                        dias['martes'] = event.target.checked
                        setPlantilla({...plantilla, dias: dias})}} name="martes" />} label="Martes" disabled={(mode==='DSP'||mode==='DLT')?true:false}/>

                    <FormControlLabel control={<Checkbox checked={plantilla.dias.miércoles} onChange={(event)=>{
                        const dias  = plantilla.dias
                        dias['miércoles'] = event.target.checked
                        setPlantilla({...plantilla, dias: dias})}} name="miercoles" />} label="Miércoles" disabled={(mode==='DSP'||mode==='DLT')?true:false}/>

                    <FormControlLabel control={<Checkbox checked={plantilla.dias.jueves} onChange={(event)=>{
                        const dias  = plantilla.dias
                        dias['jueves'] = event.target.checked
                        setPlantilla({...plantilla, dias: dias})}} name="jueves" />} label="Jueves" disabled={(mode==='DSP'||mode==='DLT')?true:false}/>   

                    <FormControlLabel control={<Checkbox checked={plantilla.dias.viernes} onChange={(event)=>{
                        const dias  = plantilla.dias
                        dias['viernes'] = event.target.checked
                        setPlantilla({...plantilla, dias: dias})}} name="viernes" />} label="Viernes" disabled={(mode==='DSP'||mode==='DLT')?true:false}/>       

                    <FormControlLabel control={<Checkbox checked={plantilla.dias.sábado} onChange={(event)=>{
                        const dias  = plantilla.dias
                        dias['sábado'] = event.target.checked
                        setPlantilla({...plantilla, dias: dias})}} name="sabado" />} label="Sábado" disabled={(mode==='DSP'||mode==='DLT')?true:false}/>        

                    <FormControlLabel control={<Checkbox checked={plantilla.dias.domingo} onChange={(event)=>{
                        const dias      = plantilla.dias
                        dias['domingo'] = event.target.checked
                        setPlantilla({...plantilla, dias: dias})}} name="domingo" />} label="Domingo" disabled={(mode==='DSP'||mode==='DLT')?true:false}/>                
                </Grid>                            
            </FormGroup>

            <Grid item xs={6}>
                <div>
                    <Typography variant="caption" /*color="primary"*/ gutterBottom /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/>Cantidad de cupos</Typography>
                    <NumericInput className="form-control" mobile min={0} max={150} step={1} value={plantilla.cuposDisponibles} autoCorrect="on" size={25} 
                    disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={(event, value) => {setPlantilla({...plantilla, cuposDisponibles:value})}}/> 
                </div>
            </Grid>                 
            <Grid item xs={6}>
                <FormControlLabel control={<Checkbox color="secondary" name="activa" value={plantilla.activa} checked={plantilla.activa ? true : false}/>}
                label="Activa" value={plantilla.activa} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                onChange={(event, value) => {setPlantilla({...plantilla, activa : value})
                }} style={{marginTop:10, marginLeft: 15}}/>
            </Grid>            
        </Grid>                        
      </DialogContent>
      <DialogActions>
      { (mode==='DSP' )?
        ( <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button> )
        :
        (<div>
          <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button>          
          <Button onClick={()=>{
              setDialogError({...dialogError, horaInicio: (plantilla.horaInicio === null), 
              _userID : (plantilla._userID === ''), 
              _claseID : (plantilla._claseID === ''),
              _ubicacionID : (plantilla._ubicacionID === '')})
              var validoOK = !((plantilla.horaInicio === null)||(plantilla._userID === null)||(plantilla._claseID === null)||(plantilla._ubicacionID === null))
              if (validoOK){
                handleClose(mode, validoOK)
              }
            }} color="primary" >
            {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
          </Button>
        </div>)
      }
      </DialogActions>
    </Dialog>

    <TableContainer className={classes.paper} component={Paper}>
      <Grid container spacing={4} >
        <Grid item xs={4}>
            <Autocomplete id="combo-box-demo" options={clases} getOptionLabel={(option) => option.titulo} style={{ width: 200 }} 
            renderInput={(params) => <TextField {...params} label="Clase" variant="outlined" />} 
            value={filters._claseID?filters._claseID:{titulo:""}} 
            onChange={(event, value) => {
              setFilters({...filters, _claseID : value})}} autoComplete = {false}/>
        </Grid>
        <Grid item xs={4}>
            <Autocomplete id="combo-box-demo" options={profesores} getOptionLabel={(option) => option.lastName} style={{ width: 200 }} 
            renderInput={(params) => <TextField {...params} label="Profesor" variant="outlined" />}
            onChange={(event, value) =>{setFilters({...filters, _userID : value})}} value={filters._userID?filters._userID:{lastName:""}}/>
        </Grid>
        <Grid item xs={4}>
            <Autocomplete id="combo-box-demo" options={ubicaciones} getOptionLabel={(option) => option.nombre} style={{ width: 200 }} 
            renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />} 
            onChange={(event, value) =>{setFilters({...filters, _ubicacionID : value})}} value={filters._ubicacionID?filters._ubicacionID:{nombre:""}}/>
        </Grid>

        <Grid item xs={8}>
          <FormLabel component="legend">Seleccione los días...</FormLabel>
            <FormControlLabel control={<Checkbox checked={filters.dias.lunes} onChange={(event)=>{
                const dias  = filters.dias
                dias['lunes'] = event.target.checked
                setPlantilla({...filters, dias: dias})}} name="lunes" />} label="Lunes" style={{width:120}}/>
            <FormControlLabel control={<Checkbox checked={filters.dias.martes} onChange={(event)=>{
                const dias  = filters.dias
                dias['martes'] = event.target.checked
                setPlantilla({...filters, dias: dias})}} name="martes" />} label="Martes" style={{width:120}}/>
            <FormControlLabel control={<Checkbox checked={filters.dias.miércoles} onChange={(event)=>{
                const dias  = filters.dias
                dias['miércoles'] = event.target.checked
                setPlantilla({...filters, dias: dias})}} name="miercoles" />} label="Miércoles" style={{width:120}}/>
            <FormControlLabel control={<Checkbox checked={filters.dias.jueves} onChange={(event)=>{
                const dias  = filters.dias
                dias['jueves'] = event.target.checked
                setPlantilla({...filters, dias: dias})}} name="jueves" />} label="Jueves" style={{width:120}}/>   
            <FormControlLabel control={<Checkbox checked={filters.dias.viernes} onChange={(event)=>{
                const dias  = filters.dias
                dias['viernes'] = event.target.checked
                setPlantilla({...filters, dias: dias})}} name="viernes" />} label="Viernes" style={{width:120}}/>       

            <FormControlLabel control={<Checkbox checked={filters.dias.sábado} onChange={(event)=>{
                const dias  = filters.dias
                dias['sábado'] = event.target.checked
                setPlantilla({...filters, dias: dias})}} name="sabado" />} label="Sábado" style={{width:120}}/>        

            <FormControlLabel control={<Checkbox checked={filters.dias.domingo} onChange={(event)=>{
                const dias  = filters.dias
                dias['domingo'] = event.target.checked
                setPlantilla({...filters, dias: dias})}} name="domingo" />} label="Domingo" style={{width:120}}/>                
        </Grid>                            
        <Grid container item xs={4}>
          <Grid item xs={6}>
              <FormControlLabel style={{marginTop:8}} control={<Checkbox color="secondary" name="activa" value={filters.activa} checked={filters.activa ? true : false} />}
                  label="Activa" value={filters.activa} onChange={event => setFilters({...filters, activa : event.target.checked})}/>
          </Grid>
          <Grid item xs={6}>
              <FormControlLabel style={{marginTop:8, marginLeft:0}} control={<Checkbox color="secondary" name="eliminado" value={filters.bajaLogica} checked={filters.bajaLogica ? true : false} />}
                  label="Eliminados" value={filters.bajaLogica} 
                  onChange={event => setFilters({...filters, bajaLogica : event.target.checked})}/>
          </Grid>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Button  variant="contained"  color="primary" className={classes.button} startIcon={<FontAwesomeIcon icon={faSearch} /*size = '2x'*/ fixedWidth style={{padding:1.5}}/>} align="right"
              onClick={()=>getPlantillas()}>Buscar</Button>
            </div>
          </Grid>          
        </Grid>
      </Grid>      
    </TableContainer>

    <TableContainer className={classes.paper} component={Paper}>
      <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>handleClickOpen('INS', '')} >
          Agregar
        </Button>
      </div>
      <Divider/>
      <Table className={classes.table} aria-label="simple table" size='small'>
        <TableHead style={{backgroundColor:'#34495e'}}>
          <TableRow >
          <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>            
              <TableCell><Typography style={{color:'white'}}>Clase</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Profesor</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Lugar</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Hora inicio</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Activa?</Typography></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>
              <TableCell align="left" >
                <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                  <Search/>
                </IconButton>                
              </TableCell>
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                  <Create />
                </IconButton>                
              </TableCell>                            
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {(row._claseID)?row._claseID.titulo:""}
              </TableCell>
              <TableCell align="left">
                {(row._userID)?row._userID.name:""} {(row._userID)?row._userID.lastName:""}
              </TableCell>
              <TableCell align="left">
                {(row._ubicacionID)?row._ubicacionID.nombre:""}
              </TableCell>
              <TableCell align="left">
                {row.horaInicio}
              </TableCell>                            
              <TableCell align="left">
                <Checkbox
                  checked={row.activa ? true : false}
                  className={classes.checkbox}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}

export default Plantillas;