import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {apiPath} from '../Config/Config';
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import NumericInput from 'react-numeric-input';

import { useWizard } from '../Components/WizardContext'

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    main:{
      paddingLeft:10,
      paddingRight:10,
      paddingTop: 20
    },
    button:{
      marginTop:15, 
      marginBottom:15,
      marginLeft:15
    }, 
    tablerow: {
      height: 33
    }
  });

function GADefinicion() {

    const classes               = useStyles();
    const [clases, setClases]           = useState([])
    const [profesores, setProfesores]   = useState([])
    const [ubicaciones, setUbicaciones] = useState([])

    const { wizardData, setWizardData } = useWizard()


    async function getClases(){
        try{
          const baseUrl = apiPath+'clasesFiltro'
          await fetch(baseUrl,{
              method: 'post',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': window.sessionStorage.getItem('token')
              },
          })
          .then(response => response.json())
          .then((responsejson)=>{
            setClases(responsejson)
          })
        }catch(error){
          console.log(error)
        }
      }
    
    async function getProfesores(){

        try{
            const baseUrl = apiPath+'usersAll'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
                body : JSON.stringify({
                  isProfesor  : true,
                  activo      : true,
                  bajaLogica  : false
                })
            })
            .then(response => response.json())
            .then((responsejson)=>{
              setProfesores(responsejson)
            })
          }catch(error){
            console.log(error)
          }


    }
    
    async function getUbicaciones(){
        try{
            const baseUrl = apiPath+'ubicacionesFiltro'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
                body: JSON.stringify({
                    activa : true,
                    bajaLogica : false
                })
            })
            .then(response => response.json())
            .then((responsejson)=>{
                setUbicaciones(responsejson)
            })
        }catch(error){
            console.log(error)
        }
    }    

      useEffect( ()=>{
        getClases()
        getProfesores()
        getUbicaciones()
        return () => {
            setClases([])
            setProfesores([])
            setUbicaciones([])
        }; 
      },[])

  return (

        <React.Fragment>

            <Typography variant="h6" gutterBottom>
                Definición Agenda
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Autocomplete id="combo-box-demo" options={clases} getOptionLabel={(option) => option.titulo} style={{ width: 250 }} 
                    /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/ renderInput={(params) => <TextField {...params} label="Clase" variant="outlined" />} 
                    value={wizardData._claseID?wizardData._claseID:{titulo:""}} 
                    onChange={(event, value) => {
                        setWizardData({...wizardData, _claseID:value})}
                        } autoComplete = {false}/>
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <Typography variant="caption">Duración</Typography>
                        <Typography variant="body1">{wizardData._claseID ? wizardData._claseID.duracion+' min.': ''}</Typography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete id="combo-box-demo" options={profesores} getOptionLabel={(option) => option.lastName} style={{ width: 250 }} 
                    /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/ renderInput={(params) => <TextField {...params} label="Profesor" variant="outlined" />}
                    onChange={(event, value) =>{setWizardData({...wizardData, _userID:value})}} value={wizardData._userID?wizardData._userID:{name:"", lastName:""}}/>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete id="combo-box-demo" options={ubicaciones} getOptionLabel={(option) => option.nombre} style={{ width: 250 }} 
                    /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/ renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />} 
                    onChange={(event, value) =>{setWizardData({...wizardData, _ubicacionID:value})}} value={wizardData._ubicacionID?wizardData._ubicacionID:{nombre:""}}/>
                </Grid>          
                <Grid item xs={6} >
                    <TextField id="time" label="Hora inicio" type="time" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaHoraInicio?wizardData.fechaHoraInicio:null/* agenda.fechaHoraInicio?agenda.fechaHoraInicio:null*/} /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/
                    onChange={(event) => {
                        const value = event.target.value
                        const fechaAux      = moment().minute(parseInt(value.substr(3, 2))).hour(parseInt(value.substr(0, 2))).format()
                        const fechaHoraFin  = moment(fechaAux).add(parseInt(wizardData._claseID.duracion), 'm').format().substr(11, 5)
                        setWizardData({...wizardData, fechaHoraInicio:value, fechaHoraFin:fechaHoraFin})}} fullWidth/>
                </Grid>
                <Grid item xs={6} >
                    <TextField id="time" label="Hora fin" type="time" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaHoraFin?wizardData.fechaHoraFin:null/*agenda.fechaHoraFin?agenda.fechaHoraFin.substr(0, 16):null*/} disabled
                    fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <div>
                    <Typography variant="caption" /*color="primary"*/ gutterBottom /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/>Cantidad de cupos</Typography>
                    <NumericInput className="form-control" mobile min={1} max={150} step={1} value={wizardData.cuposDisponibles/*agenda.cuposDisponibles*/} autoCorrect="on" size={25} 
                    /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/ onChange={(event, value) => {setWizardData({...wizardData, cuposDisponibles:value})}}/> 
                    </div>
                </Grid>                 
                <Grid item xs={6}>
                    <FormControlLabel control={<Checkbox color="secondary" name="activa" value={wizardData.activa/*agenda.activa*/} checked={wizardData.activa/*agenda.activa*/ ? true : false} />}
                    label="Activa" value={wizardData.activa} /*disabled={(mode==='DSP'||mode==='DLT')?true:false}*/
                    onChange={(event, value) => {setWizardData({...wizardData, activa : value})
                    }}/>
                </Grid>
            </Grid>

        </React.Fragment>

  );
}

export default GADefinicion;