import React from 'react';
import './App.css';
import { BrowserRouter , Switch, Route, Redirect } from 'react-router-dom';
import Home       from './pages/Home';
import Reports    from './pages/Reports';
import Products   from './pages/Products';
import Login      from './pages/Login';
import Clases     from './pages/Clases';
import Locales    from './pages/Locales'
import Agendas    from './pages/Agendas';
import Reservas   from './pages/Reservas'
import Plantillas from './pages/Plantillas'
import Personas   from './pages/Personas'
import Usuarios   from './pages/Usuarios'
import Roles      from './pages/Roles'
import Slides     from './pages/Slides'
import GeneracionAgendas from './pages/GeneracionAgendas'
import GeneracionAgendasMasiva from './pages/GeneracionAgendasMasiva'
import ProtectedRoute from './Components/ProtectedRoute'

function App() {


  return (

      <BrowserRouter>
        <Switch>
{           <Route exact path="/">
              <Redirect to="/login" />
            </Route>    }       

            <ProtectedRoute  component={Home}     titulo='Dashboard' path="/home" />
            <ProtectedRoute  component={Products} titulo='Home' path="/products" />
            <ProtectedRoute  component={Reports}  titulo='Home' path="/reports" />
            <ProtectedRoute  component={Clases}   titulo='Clases' path="/clases" />
            <ProtectedRoute  component={Locales}  titulo='Locales' path="/locales" />
            <ProtectedRoute  component={Agendas}  titulo='Agendas' path="/agendas" />
            <ProtectedRoute  component={Reservas}  titulo='Calendario' path="/reservas" />
            <ProtectedRoute  component={Plantillas}  titulo='Plantillas' path="/plantillas" />
            <ProtectedRoute  component={Personas}  titulo='Personas' path="/personas" />
            <ProtectedRoute  component={Usuarios}  titulo='Usuarios' path="/usuarios" />
            <ProtectedRoute  component={Roles}  titulo='Roles' path="/roles" />
            <ProtectedRoute  component={Slides}  titulo='Slides' path="/slides" />
            <ProtectedRoute  component={GeneracionAgendas}  titulo='Generación Agendas' path="/generacionagendas" />
            <ProtectedRoute  component={GeneracionAgendasMasiva}  titulo='Generación Agendas Masiva' path="/generacionagendasmasiva" />
          <Route component={Login}    path='/login'   />          {/* No es protectedRoute porque no requiere de seguridad */}
        </Switch>
      </BrowserRouter>

  );
}

export default App;