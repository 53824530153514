import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';

import FormGroup from '@material-ui/core/FormGroup';

import Checkbox from '@material-ui/core/Checkbox';

import { useWizard } from '../Components/WizardContext'


import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import NumericInput from 'react-numeric-input';

import {apiPath} from '../Config/Config';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    main:{
      paddingLeft:10,
      paddingRight:10,
      paddingTop: 20
    },
    button:{
      marginTop:15, 
      marginBottom:15,
      marginLeft:15
    }, 
    tablerow: {
      height: 33
    }
  });

function GAResumen() {

    const classes                       = useStyles();
    const { wizardData, setWizardData } = useWizard()

    const [clases, setClases]           = useState([])
    const [profesores, setProfesores]   = useState([])
    const [ubicaciones, setUbicaciones] = useState([])

    async function getClases(){
        try{
          const baseUrl = apiPath+'clasesFiltro'
          await fetch(baseUrl,{
              method: 'post',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': window.sessionStorage.getItem('token')
              },
          })
          .then(response => response.json())
          .then((responsejson)=>{
            setClases(responsejson)
          })
        }catch(error){
          console.log(error)
        }
      }
    
    async function getProfesores(){
        try{
            const baseUrl = apiPath+'usersFiltro'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
            })
            .then(response => response.json())
            .then((responsejson)=>{
                setProfesores(responsejson)
            })
            }catch(error){
            console.log(error)
        }
    }
    
    async function getUbicaciones(){
        try{
            const baseUrl = apiPath+'ubicacionesFiltro'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
            })
            .then(response => response.json())
            .then((responsejson)=>{
                setUbicaciones(responsejson)
            })
        }catch(error){
            console.log(error)
        }
    }   




    useEffect( ()=>{
        getClases()
        getProfesores()
        getUbicaciones()
        return () => {
            setClases([])
            setProfesores([])
            setUbicaciones([])
        }; 
    },[])

  return (
    <React.Fragment>
        <div className={classes.root}>
            <Grid container spacing={1}>


            <Grid item xs={6}>
                    <Autocomplete id="combo-box-demo" options={clases} getOptionLabel={(option) => option.titulo} style={{ width: 250 }} 
                    disabled renderInput={(params) => <TextField {...params} label="Clase" variant="outlined" />} 
                    value={wizardData._claseID?wizardData._claseID:{titulo:""}}  autoComplete = {false}/>
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <Typography variant="caption">Duración</Typography>
                        <Typography variant="body1">{wizardData._claseID ? wizardData._claseID.duracion+' min.': ''}</Typography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete id="combo-box-demo" options={profesores} getOptionLabel={(option) => option.lastName} style={{ width: 250 }} 
                    disabled renderInput={(params) => <TextField {...params} label="Profesor" variant="outlined" />} 
                    value={wizardData._userID?wizardData._userID:{name:"",lastName:""}}/>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete id="combo-box-demo" options={ubicaciones} getOptionLabel={(option) => option.nombre} style={{ width: 250 }} 
                    disabled renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" />} 
                    value={wizardData._ubicacionID?wizardData._ubicacionID:{nombre:""}}/>
                </Grid>          
                <Grid item xs={6} >
                    <TextField id="time" label="Hora inicio" type="time" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaHoraInicio?wizardData.fechaHoraInicio:null} disabled fullWidth/>
                </Grid>
                <Grid item xs={6} >
                    <TextField id="time" label="Hora fin" type="time" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaHoraFin?wizardData.fechaHoraFin:null} disabled fullWidth/>
                </Grid>
                <Grid item xs={6}>
                    <div>
                    <Typography variant="caption" /*color="primary"*/ gutterBottom disabled>Cantidad de cupos</Typography>
                    <NumericInput className="form-control" mobile min={10} max={150} step={1} value={wizardData.cuposDisponibles} autoCorrect="on" size={25} 
                    disabled /> 
                    </div>
                </Grid>                 
                <Grid item xs={6}>
                    <FormControlLabel control={<Checkbox color="secondary" name="activa" value={wizardData.activa} checked={wizardData.activa ? true : false} />}
                    label="Activa" value={wizardData.activa} disabled />
                </Grid>
                <FormLabel component="legend">Días</FormLabel>
                <FormGroup>
                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.lunes}/>} label="Lunes" disabled/>
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.martes} />} label="Martes" disabled/>
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.miércoles} />} label="Miércoles" disabled/>
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.jueves} />} label="Jueves" disabled/>   
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.viernes} />} label="Viernes" disabled/>       
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.sábado} />} label="Sabado" disabled/>        
                        <FormControlLabel control={<Checkbox checked={wizardData.dias.domingo} />} label="Domingo" disabled/>                
                    </Grid>                            
                </FormGroup>
                <FormLabel component="legend">Período de generación</FormLabel>
                <Grid item xs={6} >
                    <TextField id="date" label="Fecha inicio" type="date" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaInicioPeriodo?wizardData.fechaInicioPeriodo:null}
                    disabled fullWidth/>
                </Grid>
                <Grid item xs={6} >
                    <TextField id="date" label="Fecha fin" type="date" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={wizardData.fechaFinPeriodo?wizardData.fechaFinPeriodo:null} 
                    disabled fullWidth/>
                </Grid>
                <Grid item xs={12} >
                    <FormControlLabel control={<Checkbox checked={wizardData.generaPlantilla} onChange={(event)=>{
                        setWizardData({...wizardData, generaPlantilla: event.target.checked})}} name="generaPlantilla" />} 
                        label="Desea almacenar proceso de generación en una plantilla?" disabled/>
                </Grid>                
            </Grid>
        </div>
    </React.Fragment>
  );
}

export default GAResumen;