import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle, FormControl, FormHelperText,
  Grid, FormControlLabel, TablePagination, Divider, DialogContentText, Typography, Tooltip} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import {Create, Delete, Search, Add, Image} from '@material-ui/icons';

import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import {apiPath, timezone} from '../Config/Config'
import ImageUploading from "react-images-uploading";
import guid from 'guid'

const useStyles = makeStyles({
  table: {
    minWidth: '650',
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15
  }, 
  buttonFilter:{
      marginTop:10
  },
  tablerow: {
    height: 33
  },
  margin: {
    margin: 1,
  },
  withoutLabel: {
    marginTop: 3,
  },
  paper:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop:20,
    paddingBottom:20,
    marginBottom: 15
  }  
 
});

function Slides () {
    const classes   = useStyles();
  // + Grid
    const [data, setData]                   = useState([])
    const [isLoading, setIsLoading]         = useState(false)
  // -

  // + Formulario Slide
    const [open, setOpen]       = useState(false)

    const initialValueSlide   = {
        _id             : '',
        _appID          : {nombre:""},
        fechaCreacion   : moment().format('YYYY-MM-DDTHH:MM'),
        titulo          : '',
        descripcion     : '',
        urlImagen       : null,
        nombreArchivo   : null,
        base64Imagen    : null, 
        activa          : true,
        orden           : 0,        
        fechaInicio     : moment().format('YYYY-MM-DDTHH:MM'),
        fechaFin        : null, 
        bajaLogica      : false
    }

    const initialDialogError = {
        titulo          : false,
        descripcion     : false,
        _appID          : false,
        orden           : false,
        fechaInicio     : false,
        fechaFin        : false,
        base64Imagen    : false
    }

    const initialValueFilters = {
        fechaDesde  : moment().format('YYYY-MM-DDTHH:MM'),
        fechaHasta  : null,
        _appID      : null,
        activa      : true,
        bajaLogica  : false
    }

    const [dialogError, setDialogError] = useState(initialDialogError)
    const [filters, setFilters]         = useState(initialValueFilters)

    const [slide, setSlide] = useState(initialValueSlide)
    const [mode, setMode]   = useState('')

  // -

    const [aplicaciones, setAplicaciones] = useState({nombre:""})

    const [images, setImages] = useState([]);

    const onChange = (imageList, addUpdateIndex) => {
        if (imageList.length > 0){
            setImages(imageList);
            setSlide({...slide, base64Imagen: imageList})  
            setDialogError({...dialogError, base64Imagen: false})
        } else{
            setImages(null);
            setSlide({...slide, base64Imagen: null})
            setDialogError({...dialogError, base64Imagen: true})
        }
    };

    // + Function Formulario
        const handleClickOpen = async (modo, row) => {
            setMode(modo)
            setDialogError(initialDialogError)
            if (modo === 'INS'){
                setSlide(initialValueSlide)
                setImages([])
            }else{
                setSlide(row)
                setImages(row.base64Imagen)
            }
            //setIsLoading(false)
            setOpen(true);
        };

        const handleClose = async(modo, ValidoOK) => {
            switch (modo) {
                case 'INS':
                    // Valido datos ingresados
                    if (ValidoOK){ 
                        await agregarSlide()
                        setOpen(false);

                    }
                    break;                    
                case 'UPD':
                    // Valido datos modificados
                    if (ValidoOK){
                        await modificarSlide()
                        setOpen(false);
                    } 
                    break
                case 'DLT':
                    await eliminarSlide()
                    setOpen(false);
                    break;                    
            default:
                setOpen(false); 
                break
            }
        };
    // -

    // + Function Adicionales

        async function getAplicaciones(){
            try{
                const baseUrl = apiPath+'applicationAll'
                await fetch(baseUrl,{
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': window.sessionStorage.getItem('token')
                    },
                })
                .then(response => response.json())
                .then((responsejson)=>{
                    setAplicaciones(responsejson)
            })
            }catch(error){
            console.log(error)
            }
        }

    // - Function Adicionales

// + Function Acciones Slides.

    async function getSlides(){
        try{
            const baseUrl = apiPath+'carouselImagenAll'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
                body: JSON.stringify({
                    _appID      : filters._appID,
                    fechaDesde  : moment(filters.fechaDesde, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
                    fechaHasta  : moment(filters.fechaHasta, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
                    activa      : filters.activa, 
                    bajaLogica  : filters.bajaLogica,
                })
            })
            .then(response => response.json())
            .then((responsejson)=>{
                setData(responsejson)
            })
        }catch(error){
            console.log(error)
        }
    }  

    async function uploadImagen(ubicacion, nombreArchivo){
        try{  
            var Resultado = null
            const baseUrl = apiPath+'uploadImagen'

            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token'),
                },
                body: JSON.stringify({
                    imagenBase64: slide.base64Imagen,
                    ubicacion: ubicacion,
                    nombreArchivo: nombreArchivo
                })
            })
            .then(response => response.json())
            .then(responsejson=>{
                if(responsejson){
                    Resultado = responsejson.Resultado
                }
            })  
            return Resultado
        }catch(error){
        console.log(error)
        }         
    }

    async function agregarSlide(){
        try{  
                const ubicacion     = 'public/slides/'
                const nombreArchivo = 'Slide_' + guid.raw() + '.jpg'

                var Resultado = ''

                Resultado = await uploadImagen(ubicacion, nombreArchivo)

                if(Resultado === 'OK'){
                    const fechaCreacion         = moment().format('YYYY-MM-DD hh:mm')
                    const slideParam            = {}
                    slideParam._appID           = slide._appID._id
                    slideParam.nombreArchivo    = nombreArchivo
                    slideParam.urlImagen        = 'slides/' + nombreArchivo                
                    slideParam.fechaCreacion    = moment(fechaCreacion, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString()
                    slideParam.fechaInicio      = moment(slide.fechaInicio, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString()
                    slideParam.fechaFin         = moment(slide.fechaFin, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString()
                    slideParam.activa           = slide.activa
                    slideParam.orden            = slide.orden
                    slideParam.titulo           = slide.titulo
                    slideParam.descripcion      = slide.descripcion
                    slideParam.base64Imagen     = slide.base64Imagen
                    slideParam.bajaLogica       = slide.bajaLogica

                    console.log(slideParam)
                    const baseUrl       = apiPath+'carouselImagen'
                    await fetch(baseUrl,{
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': window.sessionStorage.getItem('token'),
                        },
                        body: JSON.stringify(slideParam)
                        }).then(response => response.json())
                        .then(responsejson=>{
                            if(responsejson){
                                let dataAux = data
                                dataAux.push(responsejson.carouselImagen)
                                setData(dataAux)
                            }
                    })  
                }
/*             }else{
                // Se ha producido un error al subir una imagen
            } */
        }catch(error){
        console.log(error)
        } 
    }

    async function modificarSlide(){
        try{
            setIsLoading(true)
            const ubicacion     = 'public/slides/'
            const nombreArchivo = slide.nombreArchivo

            var Resultado = 'OK'

            if (images.length > 0 )
                Resultado = await uploadImagen(ubicacion, nombreArchivo)

            if(Resultado === 'OK'){

                const slideParam            = slide
                slideParam._appID           = slide._appID._id
                slideParam.fechaInicio      = moment(slideParam.fechaInicio, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString()
                slideParam.fechaFin         = moment(slideParam.fechaFin, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString()
                slideParam.nombreArchivo    = nombreArchivo
                slideParam.urlImagen        = 'slides/' + nombreArchivo           

                const baseUrl       = apiPath+'updateCarouselImagen'
                await fetch(baseUrl,{
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': window.sessionStorage.getItem('token'),
                    },
                    body: JSON.stringify(slideParam)
                }).then(response => response.json())
                  .then(responsejson=>{
                    const slideUpd  = responsejson
                    let dataAux = data
        
                    dataAux.forEach(item => {
                        if(item._id === slideUpd._id){
                            item.titulo          = slideUpd.titulo
                            item.descripcion     = slideUpd.descripcion
                            item.fechaInicio     = slideUpd.fechaInicio
                            item.fechaFin        = slideUpd.fechaFin
                            item.orden           = slideUpd.orden
                            item.activa          = slideUpd.activa
                            item.base64Imagen    = slideUpd.base64Imagen
                            item.bajaLogica      = slideUpd.bajaLogica
                            item._appID          = slideUpd._appID
                            return item
                        } 
                    })
                }).then(setIsLoading(false))
            }else{
                // No se actualizo el archivo
            }
        }catch(error){
            console.log(error)
        }
    } 

    async function eliminarSlide(){
        try{

            const baseUrl = apiPath+'deleteCarouselImagen'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
                body: JSON.stringify({
                    _id : slide._id
                })
            }).then(response =>response.json())
                .then(responsejson => {
                if (responsejson.Resultado === 'OK'){
                    let dataAux = data
                    dataAux.forEach(data => {
                    if(data._id === slide._id){
                        var i = dataAux.indexOf(data)
                        i !== -1 && dataAux.splice( i, 1 )
                        return data
                    }
                    })
                }
                }).finally(()=>setIsLoading(false))
            }catch(error){
            console.log(error)
            }     
    } 

    // + Paginado
        const [rowsPerPage, setRowsPerPage] = useState(8);
        const [page, setPage]               = useState(0);

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };
    // - Paginado

// - Function Acciones.

    useEffect( ()=>{
        getSlides()
        getAplicaciones()
        return () => {
            setData([])
            setAplicaciones([])
        }; 
  },[])

  return (
    <div className = {classes.main}>
    <CssBaseline />
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth> 
        <DialogTitle id="form-dialog-title">
            {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Slide
        </DialogTitle>
        <DialogContent >
            <DialogContentText style={{color:'red'}}>
            { mode==='DLT' ? 
                ('¿Esta seguro que quiere eliminar el slide?')
                :
                ('')
            }
            </DialogContentText>           
            <Grid container spacing={3}>
                <Grid item xs={6} >
                    <TextField required id="titulo" name="titulo" label="Titulo" value={slide.titulo} 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                            setSlide({...slide, titulo:event.target.value})
                            if(event.target.value.length > 0)
                                setDialogError({...dialogError, titulo:false})
                            else
                                setDialogError({...dialogError, titulo:true})
                        }} 
                         autoComplete="given-name" fullWidth error={dialogError.titulo} helperText={dialogError.titulo?"Debe ingresar un título.":""}/>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete  id="combo-box-demo"  options={aplicaciones} getOptionLabel={(option) => option.nombre} 
                    disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Aplicación" variant="outlined" 
                    required error={dialogError._appID} helperText={dialogError._appID?"Debe seleccionar una aplicación.":""}/>} 
                     onChange={(event, value) => {
                        setSlide({...slide, _appID : value})
                        if(value === null)
                            setDialogError({...dialogError, _appID:true})
                        else
                            setDialogError({...dialogError, _appID:false})
                        }} 
                    value={slide._appID}/>
                </Grid>
                <Grid item xs={6} >
                    <TextField required id="descripcion" name="descripcion" label="Descripción" value={slide.descripcion} 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                            setSlide({...slide, descripcion:event.target.value})
                            if(event.target.value.length > 0)
                                setDialogError({...dialogError, descripcion:false})
                            else
                                setDialogError({...dialogError, descripcion:true})  
                        }} 
                        fullWidth autoComplete="given-name" multiline rows={10} error={dialogError.descripcion} helperText={dialogError.descripcion?"Debe ingresar una descripción.":""}/>
                </Grid>    
                <Grid item xs={6}>
                    <FormControl error={dialogError.base64Imagen}>
                        <ImageUploading
                            multiple={false}
                            value={images}
                            onChange={onChange}
                            dataURLKey="data_url"
                        >
                            {({
                            imageList,
                            onImageUpload,
                            onImageRemove,
                            dragProps
                            }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                                {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img src={image.data_url} alt="" width="200" />
                                    {(mode==='DSP' || mode==='DLT')?
                                (<div></div>):(<Button color="secondary" style={{marginLeft:50}} startIcon={<Delete />} onClick={() => onImageRemove(index)}>Eliminar</Button>)}
                                </div>
                                ))}
                                {(mode==='DSP' || mode==='DLT')?
                                (<div></div>):(<Button startIcon={<Image />} style={{marginTop:20}} onClick={onImageUpload} {...dragProps} variant="contained" color="primary">Añadir / Modificar</Button>)}
                                            
                            </div>
                            )}
                        </ImageUploading>
                        {(dialogError.base64Imagen)?(<FormHelperText>Debe seleccionar una imagen</FormHelperText>):<FormHelperText></FormHelperText>}
                        
                    </FormControl>                    
                </Grid>
                <Grid item xs={2} >
                    <TextField required id="orden" name="orden" label="Orden" value={slide.orden} 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                            setSlide({...slide, orden:event.target.value})
                            if(event.target.value.length > 0)
                                setDialogError({...dialogError, orden:false})
                            else
                                setDialogError({...dialogError, orden:true})  
                    }} 
                         autoComplete="given-name" fullWidth error={dialogError.orden} helperText={dialogError.orden?"Debe ingresar un orden.":""}/>
                </Grid>                

                <Grid item xs={4} >
                    <TextField id="datetime-local" label="Fecha / Hora inicio" type="datetime-local" className={classes.textField} required
                    InputLabelProps={{ shrink: true, }} value={slide.fechaInicio?slide.fechaInicio.substr(0, 16):null} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                    onChange={(event) => {
                        setSlide({...slide, fechaInicio : event.target.value})
                        if(event.target.value)
                            setDialogError({...dialogError, fechaInicio:false})
                        else
                            setDialogError({...dialogError, fechaInicio:true})  
                    }} fullWidth error={dialogError.fechaInicio} helperText={dialogError.fechaInicio?"Debe ingresar una fecha de inicio.":""}/>
                </Grid>

                <Grid item xs={4} >
                    <TextField id="datetime-local" label="Fecha / Hora fin" type="datetime-local" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={slide.fechaFin?slide.fechaFin.substr(0, 16):null} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                    onChange={(event) => {
                        setSlide({...slide, fechaFin : event.target.value})
                    }} fullWidth />
                </Grid>                                                  
                <Grid item xs={2}>
                    <FormControlLabel style={{marginTop:8}} control={<Checkbox color="secondary" name="activa" value={slide.activa} checked={slide.activa ? true : false} />}
                    label="Activa" value={slide.activa} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                    onChange={event => setSlide({...slide, activa : event.target.checked})}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            { (mode==='DSP' )?
                ( <Button onClick={()=>handleClose('')} color="primary">
                    Cancelar
                </Button> )
                :
                (<div>
                <Button onClick={()=>handleClose('')} color="primary">
                    Cancelar
                </Button>          
                <Button onClick={()=>{
                    setDialogError({...dialogError, titulo: (slide.titulo === ''), 
                                    descripcion : (slide.descripcion === ''), 
                                    orden : (slide.orden === 0),
                                    fechaInicio : (slide.fechaInicio === ''),
                                    _appID : (slide._appID === ''),
                                    base64Imagen : (slide.base64Imagen === null)})
                    var ValidoOK = !((slide.titulo === '')||(slide.descripcion === '')||(slide.orden === 0)||(slide.fechaInicio === '')||(slide._appID === null)||(slide.base64Imagen === null))
                    handleClose(mode, ValidoOK)
                    
                    
                }} color="primary" >
                    {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
                </Button>
                </div>)
            }
        </DialogActions>
    </Dialog>

    <TableContainer className={classes.paper} component={Paper}>
        <Grid container spacing={4} >
            <Grid item xs={4} >
                <TextField id="datetime-local" variant="outlined" label="Fecha / Hora Desde" type="datetime-local" style={{ width: 220 }}
                InputLabelProps={{ shrink: true, }} value={filters.fechaDesde?filters.fechaDesde.substr(0, 16):null} 
                onChange={(event) => { setFilters({...filters, fechaDesde : event.target.value}) }} fullWidth/>
            </Grid>
            <Grid item xs={4} >
                <TextField id="datetime-local" variant="outlined" label="Fecha / Hora Hasta" type="datetime-local" style={{ width: 220 }} 
                InputLabelProps={{ shrink: true, }} value={filters.fechaHasta?filters.fechaHasta.substr(0, 16):null} 
                onChange={(event) => { setFilters({...filters, fechaHasta : event.target.value}) }} fullWidth/>
            </Grid>      
            <Grid item xs={4}>
                <Autocomplete id="combo-box-demo" options={aplicaciones} getOptionLabel={(option) => option.nombre} style={{ width: 250 }} 
                renderInput={(params) => <TextField {...params} label="Aplicación" variant="outlined" />} 
                value={filters._appID} 
                onChange={(event, value) => {setFilters({...filters, _appID : value})}} autoComplete = {false}/>
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel style={{marginTop:8, marginLeft:10}} control={<Checkbox color="secondary" name="activa" value={filters.activa} checked={filters.activa ? true : false} />}
                    label="Activa" value={filters.activa} 
                    onChange={event => setFilters({...filters, activa : event.target.checked})}/>
            </Grid>
            <Grid item xs={7}>
                <FormControlLabel style={{marginTop:8, marginLeft:10}} control={<Checkbox color="secondary" name="eliminado" value={filters.bajaLogica} checked={filters.bajaLogica ? true : false} />}
                    label="Eliminados" value={filters.bajaLogica} 
                    onChange={event => setFilters({...filters, bajaLogica : event.target.checked})}/>
            </Grid>        
            <Grid item xs={2}>
                <div>
                    <Button  variant="contained"  color="primary" className={classes.buttonFilter}  startIcon={<FontAwesomeIcon icon={faSearch} /*size = '2x'*/ fixedWidth style={{padding:1.5}}/>} align="right"
                    onClick={()=>getSlides()}>Buscar</Button>
                </div>
            </Grid>
        </Grid>      
    </TableContainer>




    <TableContainer className={classes.paper} component={Paper}>
      <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>{
            handleClickOpen('INS', '')
            }} >
          Agregar
        </Button>
      </div>
      <Divider/>
      <Table className={classes.table} aria-label="simple table" size='small'>
        <TableHead style={{backgroundColor:'#34495e'}}>
          <TableRow >
              <TableCell style={{width:'5vh'}} align="left"></TableCell>
              <TableCell style={{width:'5vh'}} align="left"></TableCell>
              <TableCell style={{width:'5vh'}} align="left"></TableCell>
              <TableCell><Typography style={{color:'white'}}>Titulo</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Fecha Inicio</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Fecha Fin</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Aplicación</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Activa?</Typography></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>
                <TableCell align="left" >
                    <Tooltip title="Visualizar">
                        <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                            <Search />
                        </IconButton>                
                    </Tooltip>
                </TableCell>
                {(row.bajaLogica)?
                (<TableCell align="left">
                </TableCell>)                
                :
                (<TableCell align="left">
                    <Tooltip title="Modificar">
                        <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                            <Create />
                        </IconButton>        
                    </Tooltip>        
                </TableCell>)
                }
                {(row.bajaLogica)?
                (<TableCell align="left">
                </TableCell>)                
                :
                (
                <TableCell align="left">
                    <Tooltip title="Eliminar">
                        <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                )}
              <TableCell component="th" scope="row">
                {row.titulo}
              </TableCell>
              <TableCell align="left">
                {row.fechaInicio?row.fechaInicio.substr(8, 2) +'/'+ row.fechaInicio.substr(5, 2)+
                '/'+row.fechaInicio.substr(0, 4)+' - ' +row.fechaInicio.substr(11, 5): "Indefinido"}
              </TableCell>
              <TableCell align="left">
                {row.fechaFin?row.fechaFin.substr(8, 2) +'/'+ row.fechaFin.substr(5, 2)+
                '/'+row.fechaFin.substr(0, 4)+' - ' +row.fechaFin.substr(11, 5): "Indefinido"}
              </TableCell>
              <TableCell align="left">
                {(row._appID === null)?"":row._appID.nombre}
              </TableCell>              
              <TableCell align="left">
                <Checkbox
                  checked={row.activa ? true : false}
                  className={classes.checkbox}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}
export default Slides;