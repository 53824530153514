import axios     from 'axios';
import {apiPath, appID} from '../Config/Config'
//import md5 from 'md5'

export async function Login (usuario, contrasenia){
    try{
        //const encodePassword = md5(contrasenia)
        const baseUrl = apiPath+'users/login'
        const response = await axios({
            method: 'post',
            url: baseUrl,
            data: {
                email : usuario,
                password : contrasenia,
                app : appID
            }, 
            responseType: 'json' 
          })
          return response.data 

/*         await fetch(baseUrl, 
        {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token')
            },
            body: JSON.stringify({
                email       : usuario,
                password    : contrasenia,
                app         : 'cocoreact'
            })          
        }).then(response =>response.json())
        .then(responsejson => {
            console.log(responsejson)
            const responseLogin = responsejson
            return {responseLogin}
        }) */
    }
    catch(e){
        console.log(e)
    }
}