import React, {useState, useEffect} from 'react'
import '../pages/react-big-calendar.css';
//import '../lib/css/react-big-calendar.css'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import 'moment/locale/es';

import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Checkbox, TextField, Dialog, DialogActions,DialogContent, DialogTitle,
  Grid, TablePagination, DialogContentText, Typography, Tooltip} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { apiPath, timezone } from '../Config/Config'
 
import IconButton from '@material-ui/core/IconButton';
import { Delete, Add} from '@material-ui/icons';


const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    main:{
      paddingLeft:10,
      paddingRight:10,
      paddingTop: 20
    },
    button:{
      marginTop:15, 
      marginBottom:15,
      marginLeft:15
    }, 
    tablerow: {
     // height: 33
    },
    containerCalendar: {
        paddingRight: 20,
        paddingTop: 20,
        paddingLeft: 20,
        paddingBottom: 20
    }, 
    paper: {
        height: '60%'
    }
  });

export default function Reservas ()  {

  const [events, setEvents]   = useState([])
  const classes               = useStyles();
  moment.locale("es");
//BigCalendar.momentLocalizer(moment);
  const localizer = momentLocalizer(moment);

  // Carga todas las views posibles.
  //let allViews = Object.keys(Views).map(k => Views[k])

  const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
        backgroundColor: 'lightblue'// '#F1F8E9',
        },
    }) 
    const eventStyleGetter = (event, start, end, isSelected) => {
      const backgroundColor = event.bgColor;
      const style = {
        backgroundColor
      };
      return {
        style
      };
    }

  const [open, setOpen]               = useState(false)
  const [mode, setMode]               = useState('')
  const [openDialog, setOpenDialog]   = useState(false)

  const initialReservaValue           = {
    fechaCreacionAgenda : moment().utc(timezone).toISOString(),
    _agendaID : null,
    _usuarioID : null,
    bajaLogica: false,        
    asistio: false
  }

  const [reserva, setReserva]         = useState(initialReservaValue)
  const [usuario, setUsuario]         = useState(null)
  const [isLoading, setIsLoading]     = useState(false)  

  const [usuarios, setUsuarios]       = useState([])  
  const [dataReserva, setDataReserva] = useState([])

  //const [dataUsuarios, setDataUsuarios] = useState([])


  async function getAgendas(){
      try{
          const baseUrl = apiPath+'agendasAll'
          await fetch(baseUrl,{
              method: 'post',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': window.sessionStorage.getItem('token')
              },
              body: JSON.stringify({
                  fechaFiltro: '2020-09-20',
                  activaFiltro : true
              })
          })
          .then(response => response.json())
          .then((responsejson)=>{
            let dataAux       = responsejson

            const eventsAux   = []
            dataAux.forEach(item => {
              const event     = 
              {
                  id:     item._id,
                  title:  item._claseID.titulo + ' | ' + (item._ubicacionID?item._ubicacionID.nombre: 'Sin ubicación') +' | ' + item._userID.name,
                  allDay: false,

                  start:  new Date(parseInt(item.fechaHoraInicio.substr(0, 4)), parseInt(item.fechaHoraInicio.substr(5, 2)) - 1, 
                  parseInt(item.fechaHoraInicio.substr(8, 2)), parseInt(item.fechaHoraInicio.substr(11, 2)), parseInt(item.fechaHoraInicio.substr(14, 2)), 0,0),

                  end:    new Date(parseInt(item.fechaHoraFin.substr(0, 4)), parseInt(item.fechaHoraFin.substr(5, 2)) - 1, 
                  parseInt(item.fechaHoraFin.substr(8, 2)), parseInt(item.fechaHoraFin.substr(11, 2)), parseInt(item.fechaHoraFin.substr(14, 2)), 0,0),

                  bgColor: (item.cuposDisponibles==0?"#cd5c5c":"#8fbc8f")
              }
              eventsAux.push(event)
            })
            setEvents(eventsAux)
          })
        }catch(error){
          console.log(error)
        }        
  }

  async function getUsuarios(){
    try{
      const baseUrl = apiPath+'usersFiltro'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          }
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setUsuarios(responsejson)
      })
    }catch(error){
      console.log(error)
    }        
  }

  async function getReservas(evento){
    try{
      setIsLoading(true)
      const baseUrl = apiPath+'reservasAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
              _agendaID: evento.id
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setDataReserva(responsejson)
      }).finally(()=>setIsLoading(false))
    }catch(error){
      console.log(error)
    }      
  }

  async function agregarReserva(){
    try{
      console.log(reserva)

      setIsLoading(true)
      const baseUrl = apiPath+'reserva'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify(reserva)
      })
      .then(response => response.json())
      .then((responsejson)=>{
        if(responsejson.resultadoOK){
          let dataAux = dataReserva
          dataAux.push(responsejson.reserva)
          setDataReserva(dataAux)
        }else{

        }
              
      }).finally(()=>setIsLoading(false))
    }catch(error){
      console.log(error)
    } 
  }

  /*async function eliminarReserva(reserva){
    try{
      setIsLoading(true)
      const baseUrl = apiPath+'eliminarReservaUsuario'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
              reservaID: reserva._id
              //_usuarioID: 
              //asistio: false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        //setDataReserva(responsejson)
      }).finally(()=>setIsLoading(false))
    }catch(error){
      console.log(error)
    } 
  }*/

  useEffect( ()=>{
      getAgendas()
      getUsuarios()
    },[])

  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [page, setPage]               = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };    

  function handleClickLimpiar(){
    //setReserva(initialReservaValue)
    setUsuario(null)
  }
  
  const handleClose = () => {
    setOpen(false);    
    window.location.reload(false);

  };  

  const handleCloseMsg = () => {
    setOpenDialog(false);
  }; 

  const handleAceptarMsg = () => {
    // se elimina el traslado
    setOpenDialog(false);
  }; 


    return (
        <div className = {classes.main}>

          <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title" maxWidth='md'>
          <DialogTitle id="form-dialog-title">Reservas</DialogTitle>    
          <DialogContent>
              <DialogContentText style={{color:'red'}}>
              { mode==='DLT' ? 
                  ('¿Esta seguro que quiere eliminar la reserva?')
                  :
                  ('')
              }
              </DialogContentText>     

              <Grid container spacing={3}>
                  <Grid item xs={6}>
                      <Autocomplete id="combo-box-demo"  options={usuarios} 
                      getOptionLabel={(option) => option.documento+' - '+(option.name?option.name:'')+' '+(option.lastname?option.lastname:'')} 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Usuario" variant="outlined" 
                      required = {true}/>} value={usuario} onChange={(event, value) => 
                        {
                          setReserva({...reserva, _usuarioID : value._id})
                          setUsuario(value)
                        }
                      } autoComplete = {false}/>
                  </Grid>

                  <div>

                      <Button variant="contained" color="primary" className={classes.button} startIcon={<Add/>} align="right"
                      onClick={()=>
                        {
                          agregarReserva()
                          handleClickLimpiar()       
                        }
                      } >Agregar</Button>
                  </div>
                  
                  <Table className={classes.table} aria-label="simple table" size='small'>
                      <TableHead style={{backgroundColor:'#34495e'}}>
                        <TableRow >
                          <TableCell style={{width:'5vh'}} align="left"></TableCell>
                          <TableCell><Typography style={{color:'white'}}>Documento</Typography></TableCell>
                          <TableCell><Typography style={{color:'white'}}>Nombre</Typography></TableCell>
                          <TableCell><Typography style={{color:'white'}}>Apellido</Typography></TableCell>
                          <TableCell><Typography style={{color:'white'}}>Asistio</Typography></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {(dataReserva).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <TableRow key={row._id} className={classes.tablerow}>
                              <TableCell align="left">
                                  <Tooltip title="Eliminar">
                                      <IconButton onClick={()=>{
                                          //eliminarRoleApp(row)
                                          setOpenDialog(true)
                                          handleClickLimpiar()
                                          }}>
                                          
                                          <Delete />
                                      </IconButton>
                                  </Tooltip>
                              </TableCell>    
                            <TableCell component="th" scope="row">
                              {row._usuarioID.documento}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row._usuarioID.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row._usuarioID.lastName}
                            </TableCell>
                            <TableCell align="left">
                              
                              <Checkbox
                                checked={row.asistio ? true : false}
                                className={classes.checkbox}/>
                            </TableCell>                          
                          </TableRow>
                      ))}
                      </TableBody>
                  </Table>
                  <div>
                      <TablePagination
                          rowsPerPageOptions={[2, 4, 6]}
                          component="div"
                          count={dataReserva.length}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage='Filas por pág.'
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                      />           
                  </div>
              </Grid>
          </DialogContent>            
          <DialogActions>
              <Button onClick={()=>handleClose('')} color="primary">
                  Cancelar
              </Button>
          </DialogActions>
        </Dialog>

        <Dialog
        open={openDialog}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Esta seguro que desea eliminar la reserva?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseMsg} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAceptarMsg} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog> 

        <TableContainer className={classes.paper} component={Paper}>
            <div className = {classes.containerCalendar}>
              <Calendar
                localizer={localizer}
                events={events}
                views={[Views.DAY, Views.WEEK]}
                defaultView={Views.WEEK}

                onSelectEvent={async(event) => 
                  {
                    setReserva({...reserva, _agendaID:event.id})
                    await getReservas(event)
                    setOpen(true)
                  }
                }
                step={15}
                //showMultiDayTimes
                
                min={new Date(0, 0, 0, 8, 0)}
                max={new Date(0, 0, 0, 20, 0)}
                defaultDate={new Date(moment().toDate().getFullYear(), moment().toDate().getMonth(), moment().toDate().getDate(),0,0,0)}
                eventPropGetter={eventStyleGetter}
                components={{
                    //timeSlotWrapper: ColoredDateCellWrapper,
                    //event: ColoredDateCellWrapper
                    //eventWrapper: ColoredDateCellWrapper,                            
                }}
                messages={{
                  next: ">",
                  previous: "<",
                  today: "Hoy",
                 // month: "Mes",
                  week: "Semana",
                  day: "Día"
                }}
              />
            </div>
        </TableContainer>
      </div>
    )
} 