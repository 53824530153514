import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Checkbox,
TextField, Grid, Button } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {apiPath} from '../Config/Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek} from '@fortawesome/free-solid-svg-icons'
import Autocomplete from '@material-ui/lab/Autocomplete';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
    tablerow: {
      height: 33
    },    
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15,
  }, 

  paper:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop:20,
    paddingBottom:20,
    marginBottom: 15
  },
  buttonFilter:{
    marginTop:10
  },    
});



function Row(props) {
  const { row }         = props;
  const [open, setOpen] = React.useState(false);
  const classes         = useRowStyles();

  return (
    <React.Fragment>
      <TableRow /* className={classes.root} */ className={classes.tablerow}>
        <TableCell component="th" scope="row">{row._claseID?row._claseID.titulo:''}</TableCell>
        <TableCell align="left">{row._userID?row._userID.name:''}</TableCell>
        <TableCell align="left">{row._ubicacionID?row._ubicacionID.nombre:''}</TableCell>
        <TableCell align="left">{row.horaInicio}</TableCell>
        <TableCell align="left">{row.horaFin}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>        
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">   
                <TableCell>
                  <TableRow className={classes.root}>
                    <Typography variant="body1" gutterBottom component="div">Días</Typography>   
                  </TableRow>
                  <TableRow className={classes.root}>
                    <TableCell align="left">
                      <Typography variant="body2" gutterBottom component="div">
                        Lunes
                      </Typography>   
                      <Checkbox
                        checked={row.dias.lunes ? true : false}
                        className={classes.checkbox}/>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" gutterBottom component="div">
                        Martes
                      </Typography>   
                      <Checkbox
                        checked={row.dias.martes ? true : false}
                        className={classes.checkbox}/>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" gutterBottom component="div">
                        Miércoles
                      </Typography>   
                      <Checkbox
                        checked={row.dias.miércoles ? true : false}
                        className={classes.checkbox}/>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" gutterBottom component="div">
                        Jueves
                      </Typography>   
                      <Checkbox
                        checked={row.dias.jueves ? true : false}
                        className={classes.checkbox}/>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" gutterBottom component="div">
                        Viernes
                      </Typography>   
                      <Checkbox
                        checked={row.dias.viernes ? true : false}
                        className={classes.checkbox}/>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" gutterBottom component="div">
                        Sábado
                      </Typography>   
                      <Checkbox
                        checked={row.dias.sábado ? true : false}
                        className={classes.checkbox}/>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" gutterBottom component="div">
                        Domingo
                      </Typography>   
                      <Checkbox
                        checked={row.dias.domingo ? true : false}
                        className={classes.checkbox}/>
                    </TableCell>                                                                        
                  </TableRow>
                </TableCell>
                <TableCell>
                  <TableRow className={classes.root}>
                    <Typography variant="body1">Duración</Typography>
                  </TableRow>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <Typography variant="body2">{row._claseID.duracion}</Typography>
                    </TableCell>
                  </TableRow>                    
                </TableCell>
                <TableCell>
                  <TableRow className={classes.root}>
                    <Typography variant="body1">Cupos Disponibles</Typography>
                  </TableRow>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <Typography variant="body2">{row.cuposDisponibles} </Typography>
                    </TableCell>
                  </TableRow>                    
                </TableCell>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CollapsibleTable() {
  const classes         = useStyles();
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };  

  const handleConfirmar = () => {
    generacionAgendasMasivas()
    setOpen(false);
  };    

  const options                   = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  const [value, setValue]         = useState('');
  const [ubicacion, setUbicacion] = useState()
  const [isLoading, setIsLoading] = useState(false)

   const initialValueDias = {
    lunes     : false,
    martes    : false,
    miércoles : false,
    jueves    : false,
    viernes   : false,
    sábado    : false,
    domingo   : false
  } 

  const initialValueFilters           = {
    _ubicacionID  : '',
    fechaInicio   : '',
    fechaFin      : '',
    dias          : initialValueDias
  } 

  const [filters, setFilters]         = useState(initialValueFilters)

  const [ubicaciones, setUbicaciones] = useState([])

  useEffect( ()=>{
    getPlantillas()
    getUbicaciones()
    return () => {
        setData(null)
        setUbicaciones([])
    }; 
  }, [])

  async function getPlantillas(pDias, pUbicacion){
    try{
      console.log('getPlantillas()')
      console.log(pDias)
      var dias  = null
      dias      = initialValueDias      

      if (pDias){
        const aux               = new String(pDias)
        dias[aux.toLowerCase()] = true       
      } 

      //console.log('pUbicacion '+pUbicacion.nombre)
      const baseUrl = apiPath+'plantillasAll'

      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            _ubicacionID        : pUbicacion,
            dias                : dias,
            activa              : true,
            bajaLogica          : false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        console.log(responsejson)
        setData(responsejson)
      })//.finally(setIsLoading(false))
    }catch(error){
      console.log(error)
    }
  }  

  async function getUbicaciones(){
    try{
      //setIsLoading(true)
      console.log('Local')
      const baseUrl = apiPath+'ubicacionesFiltro'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            activa : true,
            bajaLogica : false
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setUbicaciones(responsejson)
        console.log(responsejson)
      })//.finally(setIsLoading(false))
    }catch(error){
      console.log(error)
    }
  }

  async function generacionAgendasMasivas(){
    try{
      setIsLoading(true)
      console.log(data)
      const baseUrl = apiPath+'generacionAgendasMasiva'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body : JSON.stringify({
            dia                : value,
            ubicacion           : ubicacion,
            fechaInicioPeriodo  : filters.fechaInicio?filters.fechaInicio:null,
            fechaFinPeriodo     : filters.fechaFin?filters.fechaFin:null,
            plantillas          : data
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
      }).finally(setIsLoading(false))
    }catch(error){
      console.log(error)
    }    
  }

  //<Typography variant="h6" gutterBottom component="div" style={{marginBottom:20}}>Datos Generación - {value?value.dia:'Todos los Días'} / {ubicacion?ubicacion.nombre:'Todos los Locales'}</Typography>




  return (
    <div className = {classes.main}>


      <TableContainer component={Paper} className={classes.paper}>
      <TableRow  style={{marginBottom:10}}>
        <Typography variant="h6" gutterBottom component="div" style={{marginBottom:20}}>Datos Generación -  / </Typography>        

      </TableRow>  
      <Grid container spacing={4} >

      <Grid item xs={3}>
        <Autocomplete value={value?value:null} onChange={(event, newValue) => { 
          setValue(newValue)
          getPlantillas(newValue, ubicacion)
        }}
        id="controllable-states-demo" options={options} getOptionLabel={option =>  option } style={{ width: 180 }} 
        renderInput={(params) => <TextField {...params} label="Día generación" variant="outlined" />} 
        />
      </Grid>         



        <Grid item xs={3}>
            <Autocomplete value={ubicacion?ubicacion:null} id="combo-box-demo" options={ubicaciones} getOptionLabel={(option) => option.nombre} style={{ width: 200 }} 
            renderInput={(params) => <TextField {...params} label="Local" variant="outlined" />} 
            onChange={(event, value) =>{
              setUbicacion(value)
              //console.log('onChange setUbicacion '+newUbicacion.nombre)
      
              //getPlantillas(value, newUbicacion)
              }}/>
        </Grid>




        <Grid item xs={3} >
            <TextField id="date" label="Fecha inicio" type="date" className={classes.textField} style= {{ width: 180}}
            InputLabelProps={{ shrink: true, }} value={filters.fechaInicio?filters.fechaInicio:null}
            onChange={(event, value) => {
                setFilters({...filters, fechaInicio:event.target.value})}} fullWidth/>
        </Grid>
        <Grid item xs={3} >
            <TextField id="date" label="Fecha fin" type="date" className={classes.textField} style= {{ width: 180}}
            InputLabelProps={{ shrink: true, }} value={filters.fechaFin?filters.fechaFin:null} 
            onChange={(event, value) => {
              setFilters({...filters, fechaFin:event.target.value})}} fullWidth/>
        </Grid>


        <Grid item xs={12} >
          <Typography variant="h5" /*gutterBottom*/ /*component="div"*/  style={{color:'red'}} >
            Importante
          </Typography>                         
        </Grid>
        <Grid item xs={12} >
          <Typography variant="body2" gutterBottom component="div"  style={{marginBottom:15}} >
            - La generacion masiva se realiza por dia para un período seleccionado. Al seleccionar el día se cargaran por defecto las plantillas predefinidas que generen el día indicado.
          </Typography>               
          <Typography variant="body2" gutterBottom component="div"  style={{marginBottom:15}} >            
            - En caso de querer generar para un local particular debe ser seleccionado, de lo contrario se generara para todos.
          </Typography>               
          <Typography variant="body2" gutterBottom component="div"  style={{marginBottom:15}} >                    
            - Las plantillas pueden ser definidas / modificadas en la opción plantillas o guardadas al ejecutar el proceso de generación de agendas.
          </Typography>   
        </Grid>        
      </Grid>      


        <Grid container spacing={4} >
          <Grid item xs={9} >
            <Typography style={{marginTop:20}} variant="h6" gutterBottom component="div">Plantillas</Typography>   
          </Grid>
          <Grid item xs={3} >
            <div>
              <Button /* style={{marginTop:40}} */ variant="contained"  color="primary" className={classes.button} startIcon={<FontAwesomeIcon icon={faCalendarWeek} /*size = '2x'*/ fixedWidth /*fixedHeight*/ style={{padding:1.5}}/>} align="right"
              onClick={()=>handleClickOpen()}>Generar Agendas</Button>
            </div>
          </Grid>
        </Grid>
        <Table aria-label="collapsible table" className={classes.table}  size='small' >
          <TableHead style={{backgroundColor:'#ef6c00'}}>
            <TableRow /* className={classes.tablerow} */>
              <TableCell><Typography style={{color:'white'}}>Clase</Typography></TableCell>
              <TableCell ><Typography style={{color:'white'}}>Profesor</Typography></TableCell>
              <TableCell ><Typography style={{color:'white'}}>Local</Typography></TableCell>
              <TableCell ><Typography style={{color:'white'}}>Hora Inicio</Typography></TableCell>
              <TableCell ><Typography style={{color:'white'}}>Hora Fin</Typography></TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <Row key={row._id} row={row} className={classes.tablerow}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Generación Agendas Masivas"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Se generaran agendas para los siguientes datos:
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography>Período Generación: {filters.fechaInicio?filters.fechaInicio.substr(8,2)+'/'+filters.fechaInicio.substr(5,2)+'/'+filters.fechaInicio.substr(0,4):""} - {filters.fechaFin?filters.fechaFin.substr(8,2)+'/'+filters.fechaFin.substr(5,2)+'/'+filters.fechaFin.substr(0,4):""}</Typography>
            <Typography color="secondary">Día/s: {value?value:"TODOS"}</Typography>
            <Typography>Locales: {ubicacion?ubicacion:"TODOS"}</Typography>
          </DialogContentText>                      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmar} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>


      </div>
  );
}