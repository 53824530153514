import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import {useWizard} from '../Components/WizardContext'

import GADefinicion from '../pages/GADefinicion';
import GAPeriodo    from '../pages/GAPeriodo';
import GAResumen    from '../pages/GAResumen';
import moment from 'moment'

import {apiPath, timezone} from '../Config/Config';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Definición Agenda', 'Período generación', 'Resumen'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <GADefinicion />;
      
    case 1:
      return <GAPeriodo />;
      
    case 2:
      return <GAResumen />;

    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout() {

  const classes                     = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const { wizardData, setWizardData } = useWizard()

  async function generacionAgendaMasivas(){
    try{
      const baseUrl = apiPath+'generacionAgendas'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify(wizardData)
      })
      .then(response => response.json())
      .then((responsejson)=>{
        //setClases(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }   

  async function generarPlantilla(){
    try{
      const baseUrl   = apiPath+'plantilla'
      const fechaNow  = moment().format('YYYY-MM-DD hh:mm')       
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            
            fechaCreacion : moment(fechaNow, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),
            _claseID: wizardData._claseID._id,
            _userID: wizardData._userID._id,
            _ubicacionID: wizardData._ubicacionID._id,
            dias: wizardData.dias,
            horaInicio : wizardData.fechaHoraInicio,
            horaFin: wizardData.fechaHoraFin,
            cupos: wizardData.cuposDisponibles,
            bajaLogica : false,
            activa : true,
            fechaCreacion: moment().format()
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        console.log(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }        

  const handleNext = async() => {
    setActiveStep(activeStep + 1);

    if (activeStep === steps.length - 1){
        console.log('Ejecucion de generacion masiva')
        await generacionAgendaMasivas()
        if (wizardData.generaPlantilla){
          generarPlantilla()
        }

    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
        <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Generación agendas
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label) => (
                        <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                    </Stepper>
                    <React.Fragment>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                Thank you for your order.
                            </Typography>
                            <Typography variant="subtitle1">
                                
                                Your order number is #2001539. We have emailed your order confirmation, and will
                                send you an update when your order has shipped.
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                        {getStepContent(activeStep)}
                        <div className={classes.buttons}>
                            {activeStep !== 0 && (
                            <Button onClick={handleBack} className={classes.button}>
                                Volver
                            </Button>
                            )}
                            <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            >
                            {activeStep === steps.length - 1 ? 'Generar' : 'Siguiente'}
                            </Button>
                        </div>
                        </React.Fragment>
                    )}
                    </React.Fragment>
                </Paper>
            </main>
    </React.Fragment>
  );
}