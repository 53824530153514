import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle, FormControl, FormHelperText, 
  Grid, FormControlLabel, TablePagination, Divider, DialogContentText, Typography} from '@material-ui/core';

import ImageUploading from "react-images-uploading";
import IconButton from '@material-ui/core/IconButton';
import {Create, Delete, Search, Add, Image} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {apiPath, timezone} from '../Config/Config'

import moment from 'moment'
import guid from 'guid'

const useStyles = makeStyles({
  table: {
    minWidth: '650',
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15
  }, 
  tablerow: {
    height: 33
  },
  paper:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop:20,
    paddingBottom:20,
    marginBottom: 15
  },
  buttonFilter:{
    marginTop:10
},
});

function Locales () {
  const classes = useStyles();
  // + Grid
    const [data, setData]           = useState([])
  // -

  // + Formulario Clase
    const [open, setOpen]               = useState(false)

  const initialValueLocal   = {
    _id           : '',
    nombre        : '',
    descripcion   : '',
    direccion     : '',
    latitud       : '',
    longitud      : '',
    base64Imagen  : null,
    urlImagen     : '',
    fechaCreacion : moment().format('YYYY-MM-DDTHH:MM'),
    activa        : true,
  }

  const initialValueFilters = {
    nombre      : '',
    activa      : true,
    bajaLogica  : false
}

  const initialDialogError = {
    nombre      : false,
    longitud    : false,  
    descripcion : false,
    base64Imagen: false
}

const [dialogError, setDialogError] = useState(initialDialogError)
const [filters,     setFilters]     = useState(initialValueFilters)

  const [local, setLocal] = useState(initialValueLocal)
  const [mode, setMode]   = useState('')
  // -

  // + Function Formulario
  const handleClickOpen = (modo, row) => {
    setMode(modo)
    setDialogError(initialDialogError)    
    if (modo === 'INS')
    {
      setLocal(initialValueLocal)
      setImages([])
    }else{
      setLocal(row)
      setImages(row.base64Imagen)      
    }
    setOpen(true);
  };

  const handleClose = async(modo, validoOK) => {
    switch (modo) {
      case 'INS':
        if (validoOK){
          await agregarLocal()
          setOpen(false);
        }
        break;
      case 'UPD':
        if (validoOK) {
          await modificarLocal()
          setOpen(false);
        }
        break
      case 'DLT':
          await eliminarLocal()
          setOpen(false);
          break;        
      default:
        setOpen(false);
        break
    }
  };
  // -

  const [images, setImages] = useState([]);

  const onChange = (imageList, addUpdateIndex) => {
      if (imageList.length > 0){
          setImages(imageList);
          setLocal({...local, base64Imagen: imageList})  
          setDialogError({...dialogError, base64Imagen: false})
      } else{
          setImages(null);
          setLocal({...local, base64Imagen: null})
          setDialogError({...dialogError, base64Imagen: true})
      }
  };


  async function getLocales(){
    try{
      const baseUrl = apiPath+'ubicacionAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body:JSON.stringify({
            nombre    : filters.nombre,
            activa    : filters.activa,
            bajaLogica: filters.bajaLogica
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setData(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }  

  async function uploadImagen(ubicacion, nombreArchivo){
    try{  
        var Resultado = null
        const baseUrl = apiPath+'uploadImagen'

        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
                imagenBase64  : local.base64Imagen,
                ubicacion     : ubicacion,
                nombreArchivo : nombreArchivo
            })
        })
        .then(response => response.json())
        .then(responsejson=>{
            if(responsejson){
                Resultado = responsejson.Resultado
            }
        })  
        return Resultado
    }catch(error){
      console.log(error)
    }         
}

  // + Function Acciones.
  const agregarLocal = async() => {
     try{  

      const ubicacion     = 'public/locales/'
      const nombreArchivo = 'Local_' + guid.raw() + '.jpg'
      var Resultado       = ''
      Resultado           = await uploadImagen(ubicacion, nombreArchivo)

      if (Resultado = 'OK'){

        const baseUrl = apiPath+'ubicacion'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
              fechaCreacion : moment(local.fechaCreacion, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),              
              nombre        : local.nombre,
              descripcion   : local.descripcion,
              direccion     : local.direccion,
              latitud       : local.latitud,
              longitud      : local.longitud,
              urlImagen     : 'locales/' + nombreArchivo,
              base64Imagen  : images,
              activa        : local.activa,
              bajaLogica    : false
            })
        }).then(response => response.json())
        .then(responsejson=>{
            if(responsejson){
              let dataAux = data
              dataAux.push(responsejson.ubicacion)
              setData(dataAux)
            }
          })  
        }
        //else
          // Se ha producido un error al subir una imagen
    }catch(error){
      console.log(error)
    } 
  }

  const modificarLocal = async() => {
    try{
      const ubicacion     = 'public/locales/'
      var nombreArchivo   = local.urlImagen.replace("locales/", "")
      var Resultado       = 'OK'

      if (images.length > 0 )
          Resultado = await uploadImagen(ubicacion, nombreArchivo)

      if(Resultado === 'OK'){
        const baseUrl = apiPath+'updateUbicacion'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
              _id           : local._id,
              fechaCreacion : moment(local.fechaCreacion, 'YYYY-MM-DD hh:mm').utc(timezone).toISOString(),              
              nombre        : local.nombre,
              descripcion   : local.descripcion,
              direccion     : local.direccion,
              latitud       : local.latitud,
              longitud      : local.longitud,
              base64Imagen  : images,
              activa        : local.activa            
            })
        }).then(response => response.json())
          .then(responsejson => {
            const ubicacion = responsejson.ubicacion
            let dataAux     = data

            dataAux.forEach(item => {
              if(item._id === ubicacion._id){
                item.nombre       = ubicacion.nombre
                item.direccion    = ubicacion.direccion
                item.descripcion  = ubicacion.descripcion
                item.latitud      = ubicacion.latitud
                item.longitud     = ubicacion.longitud
                item.base64Imagen = ubicacion.base64Imagen
                item.activa       = ubicacion.activa
                return item
              }
            })
          })
        }          
      }catch(error){
        console.log(error)
      }
  }

  const eliminarLocal = async() => {
    try{
      const baseUrl = apiPath+'deleteUbicacion'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            _id : local._id
          })          
      }).then(response =>response.json())
        .then(responsejson => {
          if (responsejson.Resultado === 'OK'){
            let dataAux = data
            dataAux.forEach(item => {
              if(item._id === local._id){
                var i = dataAux.indexOf(item)
                i !== -1 && dataAux.splice( i, 1 )
                return item
              }
            })
          }
        })
    }catch(error){
      console.log(error)
    }    
  }

  // - Function Acciones.

  useEffect( ()=>{
    getLocales()
    return () => {
      setData([])
    }; 
  },[])

// + Paginado
const [rowsPerPage, setRowsPerPage] = React.useState(8);
const [page, setPage]               = React.useState(0);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
// - Paginado

  return (
    <div className = {classes.main}>
    <CssBaseline />
    <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
      <DialogTitle id="form-dialog-title">
      {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Local</DialogTitle>
        <DialogContent>
        <DialogContentText style={{color:'red'}}>
          { mode==='DLT' ? 
            ('¿Esta seguro que quiere eliminar el local?')
            :
            ('')
          }
        </DialogContentText>           
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <TextField required id="nombre" name="nombre" label="Nombre" value={local.nombre} disabled={(mode==='DSP'||mode==='DLT')?true:false}
              onChange={event => {
                setLocal({...local, nombre:event.target.value})
                if (event.target.value)
                  setDialogError({...dialogError, nombre : false})
                else
                  setDialogError({...dialogError, nombre : true})
                }} error ={dialogError.nombre} helperText={dialogError.nombre?"Debe ingresar un nombre.":""} fullWidth autoComplete="given-name" />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel control={<Checkbox color="secondary" name="activa" value="yes" checked={local.activa ? true : false} 
              onChange={event => setLocal({...local, activa:event.target.checked})}/>} label="Activa" value={local.activa} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
          </Grid>
          <Grid item xs={7}>
            <TextField required id="descripcion" value={local.descripcion} name="descripcion" label="Descripción" disabled={(mode==='DSP'||mode==='DLT')?true:false}
              onChange={event => {
                setLocal({...local, descripcion : event.target.value})
                if (event.target.value)
                  setDialogError({...dialogError, descripcion : false})
                else
                  setDialogError({...dialogError, descripcion : true})
              }} error={dialogError.descripcion} helperText={dialogError.nombre?"Debe ingresar una descripción.":""} fullWidth autoComplete="shipping address-line1" multiline rows={12}/>
          </Grid>
          <Grid container spacing={3}>
          <Grid item xs={5} direction='row'>
              <FormControl /*error={dialogError.base64Imagen}*/>
                  <ImageUploading multiple={false} value={images} onChange={onChange} dataURLKey="data_url">
                      {({imageList, onImageUpload, onImageRemove, dragProps}) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                          {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="200" />
                              {(mode==='DSP' || mode==='DLT')?
                          (<div></div>):(<Button color="secondary" style={{marginLeft:50}} startIcon={<Delete />} onClick={() => onImageRemove(index)}>Eliminar</Button>)}
                          </div>
                          ))}
                          {(mode==='DSP' || mode==='DLT')?
                          (<div></div>):(<Button startIcon={<Image />} style={{marginTop:20}} onClick={onImageUpload} {...dragProps} variant="contained" color="primary">Añadir / Modificar</Button>)}
                      </div>
                      )}
                  </ImageUploading>
                  {/*(dialogError.base64Imagen)?(<FormHelperText>Debe seleccionar una imagen</FormHelperText>):<FormHelperText></FormHelperText>*/}
              </FormControl>                    
          </Grid>          
          </Grid>
          <Grid item xs={7}>
              <TextField required id="direccion" value={local.direccion} name="direccion" label="Dirección" disabled={(mode==='DSP'||mode==='DLT')?true:false}
              onChange={event => {
                setLocal({...local, direccion:event.target.value})
                if (event.target.value)
                  setDialogError({...dialogError, direccion : false})
                else
                  setDialogError({...dialogError, direccion : true})
                }} error={dialogError.latitud} helperText={dialogError.latitud?"Debe ingresar la dirección.":""} fullWidth autoComplete="shipping address-line1" multiline rows={1}/>
          </Grid>

          <Grid item xs={2.5}>
            <TextField required id="latitud" value={local.latitud} name="latitud" label="Latitud" disabled={(mode==='DSP'||mode==='DLT')?true:false}
              onChange={event => {
                setLocal({...local, latitud:event.target.value})
                if (event.target.value)
                  setDialogError({...dialogError, latitud : false})
                else
                  setDialogError({...dialogError, latitud : true})
              }} error={dialogError.latitud} helperText={dialogError.latitud?"Debe ingresar la latitud.":""} fullWidth autoComplete="shipping address-line1" style={{width:150}}/>
          </Grid>                    
          <Grid item xs={2.5}>
            <TextField required id="longitud" value={local.longitud} name="longitud" label="Longitud" disabled={(mode==='DSP'||mode==='DLT')?true:false}
              onChange={event => {
                setLocal({...local, longitud:event.target.value})
                if (event.target.value)
                  setDialogError({...dialogError, longitud : false})
                else
                  setDialogError({...dialogError, longitud : true})                                
              }} error={dialogError.longitud} helperText={dialogError.longitud?"Debe ingresar la longitud.":""} fullWidth autoComplete="shipping address-line1" style={{width:150}}/>
          </Grid>                    
        </Grid>
      </DialogContent>
      <DialogActions>
      { (mode==='DSP' )?
        ( 
          <div>
            <Button onClick={()=>handleClose('')} color="primary">
              Cancelar
            </Button> 
          </div>
            )
        :
        (<div>
          <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button>          
          <Button onClick={()=>{
                    setDialogError({...dialogError, nombre: (local.nombre === ''), 
                    descripcion : (local.descripcion === ''), 
                    direccion : (local.direccion === 0),
                    latitud : (local.latitud === ''),
                    longitud : (local.longitud === ''),
                    base64Imagen : (local.base64Imagen === null)})
            var validoOK = !((local.nombre === '')||(local.descripcion === '')||(local.direccion === '')||(local.latitud === '')||(local.longitud === '')||(local.base64Imagen === null))            
            handleClose(mode, validoOK)

            }} color="primary" >
            {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
          </Button>
        </div>)
      }
      </DialogActions>
    </Dialog>
    <TableContainer className={classes.paper} component={Paper}>
        <Grid container spacing={4} >
            <Grid item xs={3}>
              <TextField required id="direccion" value={filters.nombre} name="nombre" label="Nombre" disabled={(mode==='DSP'||mode==='DLT')?true:false}
              onChange={event => setFilters({...filters, nombre : event.target.value})} fullWidth autoComplete="shipping address-line1" multiline rows={1}/>
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel style={{marginTop:8, marginLeft:10}} control={<Checkbox color="secondary" name="activa" value={filters.activa} checked={filters.activa ? true : false} />}
                    label="Activa" value={filters.activa} onChange={event => setFilters({...filters, activa : event.target.checked})}/>
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel style={{marginTop:8, marginLeft:10}} control={<Checkbox color="secondary" name="eliminado" value={filters.bajaLogica} checked={filters.bajaLogica ? true : false} />}
                    label="Eliminados" value={filters.bajaLogica} 
                    onChange={event => setFilters({...filters, bajaLogica : event.target.checked})}/>
            </Grid>
            <Grid item xs={2}>
                <div>
                    <Button  variant="contained"  color="primary" className={classes.buttonFilter}  startIcon={<FontAwesomeIcon icon={faSearch} fixedWidth style={{padding:1.5}}/>} align="right"
                    onClick={()=>getLocales()}>Buscar</Button>
                </div>
            </Grid>
        </Grid>      
    </TableContainer>
    <TableContainer className={classes.paper} component={Paper}>
      <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>handleClickOpen('INS', '')} >
          Agregar
        </Button>
      </div>
      <Divider/>
      <Table className={classes.table} aria-label="simple table" size='small'>
        <TableHead style={{backgroundColor:'#34495e'}}>
          <TableRow >
          <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>            
              <TableCell><Typography style={{color:'white'}}>Nombre</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Dirección</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Activa?</Typography></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>
              <TableCell align="left" >
                <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                  <Search />
                </IconButton>                
              </TableCell>
              {(row.bajaLogica)?(<TableCell align="left"></TableCell>):(
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                  <Create />
                </IconButton>                
              </TableCell>)}
              {(row.bajaLogica)?(<TableCell align="left"></TableCell>):(
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                  <Delete />
                </IconButton>
              </TableCell>)}
              <TableCell component="th" scope="row">
                {(row.nombre)?row.nombre:""}
              </TableCell>
              <TableCell align="left">
                {(row.direccion)?row.direccion:""}
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={row.activa ? true : false}
                  className={classes.checkbox}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}

export default Locales;