import React, {useState, useEffect} from 'react';
import {TextField, CssBaseline, Button, Avatar, Box, OutlinedInput, InputLabel, InputAdornment, IconButton,
FormControl} from '@material-ui/core';



import move_logo from '../assets/imagenes/move_logo.jpeg';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Login} from '../Services/LoginService'
import Copyright from '../Components/Copyright'

import {isAuthenticated, deleteTokenUser} from '../Services/AuthService'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {

  const classes                       = useStyles();
  const [usuario, setUsuario]         = useState('')
  const [contrasenia, setContrasenia] = useState('')
  
  useEffect(()=>{
    validoSesion()
  }, [])

  const iniciarSesion = async(event) => {
    event.preventDefault()
    const response =   await Login(usuario, contrasenia)
    console.log(response)
    if (response.status === 'OK')
    {
      window.sessionStorage.setItem("auth", true);
      window.sessionStorage.setItem("token", response.token);
      window.localStorage.setItem("user", JSON.stringify(response))
      window.location='/home'

    }
    else
    {
      alert(response.mensaje)
    }
  }

  const validoSesion = () => {
    const loginOK =  isAuthenticated()
    if (loginOK){
      window.location='/home'
    }
    else{

      const user = window.localStorage.getItem('user')
      if (user)
      {
        // Significa qud el usuario cerro el browser sin hacer logout
        deleteTokenUser()
        window.localStorage.clear()
      }
    }
  }

  // + Show Password

  const [showPassword, setShowPassword]           = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword );
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

// - Show Password
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={move_logo} />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={classes.form} noValidate>
          <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address"
            name="email" autoComplete="email" autoFocus onChange={event => setUsuario(event.target.value)} defaultValue={usuario}
          />
          <FormControl variant="outlined" fullWidth style={{marginTop:15}}>
              <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={contrasenia}
                onChange={event => {setContrasenia(event.target.value)}}
                endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                  </InputAdornment>
                }
                labelWidth={95}
              />
          </FormControl>              
      <Box mt={4}>
        
      </Box>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={event => iniciarSesion(event)}>
            Inciar sesión
          </Button>
        </form>
      </div>
      <Box mt={4}>
        <Copyright />
      </Box>
    </Container>
  );
}

/* <Grid container>
<Grid item xs>
  <Link href="#" variant="body2">
    Forgot password? + {usuario}
  </Link>
</Grid>
<Grid item>
  <Link href="#" variant="body2">
    {"Don't have an account? Sign Up"}
  </Link>
</Grid>
</Grid> */

/* <FormControlLabel
control={<Checkbox value="remember" color="primary" />}
label="Remember me"
/> */

