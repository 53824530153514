import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle,
  Grid, FormControlLabel, TablePagination, Divider, DialogContentText, Typography, Tooltip} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import {Create, Delete, Search, Add} from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTabletAlt, faEraser } from '@fortawesome/free-solid-svg-icons'
import Autocomplete from '@material-ui/lab/Autocomplete';

import {apiPath} from '../Config/Config'

//import axios from 'axios'

const useStyles = makeStyles({
  table: {
    minWidth: '650',
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15
  }, 
  tablerow: {
    height: 33
  },
  margin: {
    margin: 1,
  },
  withoutLabel: {
    marginTop: 3,
  },
 
});

function Roles () {
    const classes                       = useStyles();
    const [isLoading, setIsLoading]     = useState(false)
  // + Grid
    const [data, setData]               = useState([])
    const [dataRoleApp, setDataRoleApp] = useState([])
  // -

  // + Formulario Role
    const [open, setOpen]    = useState(false)
    const initialValueRole   = {
        nombre : '',
        administrador : false,
        activo: true,        
        bajaLogica : false
    }
    const [role, setRole] = useState(initialValueRole)
    const [mode, setMode] = useState('')

    const [aplicaciones, setAplicaciones] = useState([])

    const initialValueRoleApp = {
      _roleID     : role._id,
      _appID      : null,
      activa      : false,
      bajaLogica  : false
    }
    const [roleApp, setRoleApp] = useState(initialValueRoleApp)
  // -

  // + Function Formulario
  const handleClickOpen = async(modo, role) => {
      setMode(modo)
    if (modo === 'INS')
    {
        setRole(initialValueRole)
    }else{
        setRole(role)
        if ( modo === 'APP')
        {
          setRoleApp(initialValueRoleApp)
          await getRoleApp(role._id)
        }
          
    }
    setOpen(true);
  };

  const handleClose = async(modo) => {
    switch (modo) {
      case 'INS':
        await agregarRole()
        setOpen(false);
        break;
      case 'DLT':
        await eliminarRole()
        setOpen(false);
        break;
      case 'UPD':
        await modificarRole()
        setOpen(false);
        break
      default:
        setOpen(false);
        break
    }
  };
  // -

  // + Role

  async function getRoles(){
    try{
        const baseUrl = apiPath+'rolesAll'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token')
            },
        })
        .then(response => response.json())
        .then((responsejson)=>{
            setData(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }    

  const agregarRole = async() => {
      try{  
        const baseUrl = apiPath+'role'
          await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token'),
          },
          body: JSON.stringify(role)
      }).then(response => response.json())
      .then(responsejson=>{
          if(responsejson){
            let dataAux = data
            dataAux.push(responsejson.role)
            setData(dataAux)
          }
        })  
    }catch(error){
      console.log(error)
    }  
  }

  const modificarRole = async() => {
      try{
      const baseUrl = apiPath+'updateRole'
      //setIsLoading(true)
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token'),
          },
          body: JSON.stringify(role)
        }).then(response => response.json())
          .then(responsejson => {
            //const role    = responsejson
            let dataAux   = data

            dataAux.forEach(role => {
                if(role._id === responsejson._id){
                    role.nombre          = responsejson.nombre
                    role.administrador   = responsejson.administrador
                    role.activo          = responsejson.activo
                    return role
                } 
            })
        })
    }catch(error){
      console.log(error)
    }  
}

  const eliminarRole = async() => {
    try{
        const baseUrl = apiPath+'deleteRole'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token')
            },
            body: JSON.stringify({
                _id : role._id
            })
        }).then(response =>response.json())
            .then(responsejson => {
            if (responsejson.Resultado === 'OK'){
                let dataAux = data
                dataAux.forEach(roleAux => {
                if(roleAux._id === role._id){
                    var i = dataAux.indexOf(roleAux)
                    i !== -1 && dataAux.splice( i, 1 )
                    return roleAux
                }
                })
            }
            })
        }catch(error){
        console.log(error)
    }
}

  // + Paginado Role
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [page, setPage]               = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  // - Paginado

// - Role

  useEffect( ()=>{
    getRoles()
    getAplicaciones()
    return () => {
      setData([])
      setDataRoleApp([])
      setAplicaciones([])
    }; 
  },[])

// + Role / App

  const [nombreBoton, setNombreBoton] = useState('Agregar')

  async function getAplicaciones(){
    try{
        const baseUrl = apiPath+'applicationAll'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token')
            },
        })
        .then(response => response.json())
        .then((responsejson)=>{
            setAplicaciones(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }

  async function getRoleApp(_roleID){
    try{
      const baseUrl = apiPath+'roleAppAll'

      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            _roleID: _roleID
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setDataRoleApp(responsejson)
      })
    }catch(error){
      console.log(error)
    }  
  }

  async function agregarRoleApp(){
    try{
      setIsLoading(true)
      const pRoleApp    = roleApp
      pRoleApp._roleID  = role._id
      pRoleApp._appID   = roleApp._appID._id
      pRoleApp.activa   = true
      
      const baseUrl = apiPath+'roleApp'

      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify(pRoleApp)
      })
      .then(response => response.json())
      .then((responsejson)=>{
        if(responsejson){
          let dataAux = dataRoleApp
          dataAux.push(responsejson.roleApp)
          setDataRoleApp(dataAux)
        }
      }).finally(()=>setIsLoading(false))
    }catch(error){
      console.log(error)
    }  
  }

  async function eliminarRoleApp(pRoleApp){
    try{
      setIsLoading(true)
      
      const baseUrl = apiPath+'deleteRoleApp'

      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify({
            _id : pRoleApp._id
          })
      })
      .then(response => response.json())
      .then((responsejson)=>{
        if (responsejson.Resultado === 'OK'){
          let dataAux = dataRoleApp

          dataAux.forEach(item => {
              if (item._id === pRoleApp._id)
              {
                var i = dataAux.indexOf(item)
                i !== -1 && dataAux.splice( i, 1 )
                return item
              }
          })
      }
      }).finally(()=>setIsLoading(false))
    }catch(error){
      console.log(error)
    }  
  }

  async function modificarRoleApp(){
    try{
      setIsLoading(true)
      
      const baseUrl = apiPath+'updateRoleApp'

      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
          body: JSON.stringify(roleApp)
      })
      .then(response => response.json())
      .then((responsejson)=>{
        let dataAux = dataRoleApp

        dataAux.forEach(item => {
            if(item._id === responsejson._id){
                item._appID    = responsejson._appID
                item.activa    = responsejson.activa
                return item
            } 
        })
      }).finally(()=>setIsLoading(false))
    }catch(error){
      console.log(error)
    }  
  }


  function handleClickLimpiar(){
    setRoleApp(initialValueRoleApp)
    setNombreBoton('Agregar')
  }

    // + Paginado Role / Application

      const [rowsPerPageRA, setRowsPerPageRA] = useState(4);
      const [pageRA, setPageRA]               = useState(0);

      const handleChangePageRA = (event, newPage) => {
          setPageRA(newPage);
      };

      const handleChangerowsPerPageRA = (event) => {
          setRowsPerPageRA(parseInt(event.target.value, 6));
          setPageRA(0);
      };

    // - Paginado

// - Role / App

  return (
    <div className = {classes.main}>
    <CssBaseline />
    { 
      (mode==='APP') ?
      
        <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title" maxWidth='md'>
        <DialogTitle id="form-dialog-title">Relación Role / Aplicación </DialogTitle>    
        <DialogContent>
            <DialogContentText style={{color:'red'}}>
            { mode==='DLT' ? 
                ('¿Esta seguro que quiere eliminar la realción Role / Aplicación?')
                :
                ('')
            }
            </DialogContentText>     

            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Autocomplete id="combo-box-demo"  options={aplicaciones} getOptionLabel={(option) => option.nombre} 
                    disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Aplicación" variant="outlined" 
                    required = {true}/>} value={roleApp._appID} onChange={(event, value) => {setRoleApp({...roleApp, _appID : value})}} autoComplete = {false}/>
                </Grid>


                <Grid item xs={2} >
                    <FormControlLabel control={<Checkbox color="secondary" name="activa" value="yes" checked={roleApp.activa ? true : false} 
                        onChange={event => setRoleApp({...roleApp, activa : event.target.checked})}/>} label="Activa" value={roleApp.activa} 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
                </Grid>
                <div>

                    <Button variant="contained" color="primary" className={classes.button} startIcon={nombreBoton==='Agregar'?<Add />:<Create />} align="right"
                    onClick={()=>{
                        if (nombreBoton === 'Agregar')
                        {
                            agregarRoleApp()
                            handleClickLimpiar()       
                        }      
                        if (nombreBoton === 'Modificar')
                        {
                            modificarRoleApp()
                            handleClickLimpiar()
                        }
                    }
                    //handleClickUserRole()
                    } >{nombreBoton}</Button>

                    <Button variant="contained" color="inherit" className={classes.button} startIcon={<FontAwesomeIcon icon={faEraser} /*size = '2x'*/ fixedWidth style={{padding:1.5}}/>} align="right"
                    onClick={()=>handleClickLimpiar()} >Borrar</Button>      

                </div>
                
                <Table className={classes.table} aria-label="simple table" size='small'>
                    <TableHead style={{backgroundColor:'#34495e'}}>
                      <TableRow >
                        <TableCell style={{width:'5vh'}} align="left"></TableCell>
                        <TableCell style={{width:'8vh'}} align="left"></TableCell>            
                        <TableCell><Typography style={{color:'white'}}>Aplicación</Typography></TableCell>
                        <TableCell><Typography style={{color:'white'}}>Activa?</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {(dataRoleApp).slice(pageRA * rowsPerPageRA, pageRA * rowsPerPageRA + rowsPerPageRA).map((row) => (
                        <TableRow key={row._id} className={classes.tablerow}>
                            <TableCell align="left">
                                <Tooltip title="Modificar">
                                    <IconButton onClick={()=>{
                                        setRoleApp(row)
                                        setNombreBoton('Modificar')}}>
                                        <Create />
                                    </IconButton>        
                                </Tooltip>        
                            </TableCell>                            
                            <TableCell align="left">
                                <Tooltip title="Eliminar">
                                    <IconButton onClick={()=>{
                                        eliminarRoleApp(row)
                                        handleClickLimpiar()
                                        }}>
                                        
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>    
                        <TableCell component="th" scope="row">
                            {row._appID.nombre}
                        </TableCell>
                        <TableCell align="left">
                            <Checkbox
                            checked={row.activa ? true : false}
                            className={classes.checkbox}/>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <div>
                    <TablePagination
                        rowsPerPageOptions={[2, 4, 6]}
                        component="div"
                        count={dataRoleApp.length}
                        rowsPerPage={rowsPerPageRA}
                        labelRowsPerPage='Filas por pág.'
                        page={pageRA}
                        onChangePage={handleChangePageRA}
                        onChangeRowsPerPage={handleChangerowsPerPageRA}
                    />           
                </div>
            </Grid>
        </DialogContent>            
        <DialogActions>
            <Button onClick={()=>handleClose('')} color="primary">
                Cancelar
            </Button>
        </DialogActions>
      </Dialog>
      :
      <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
      {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Role</DialogTitle>
        <DialogContent>
        <DialogContentText style={{color:'red'}}>
          { mode==='DLT' ? 
            ('¿Esta seguro que quiere eliminar el Role?')
            :
            ('')
          }
        </DialogContentText>           
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField required id="nombre" name="nombre" label="Nombre" value={role.nombre} 
                    disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => setRole({...role, nombre:event.target.value})} 
                    fullWidth autoComplete="given-name" />
            </Grid>                        
            <Grid item xs={12} sm={6}>
                <FormControlLabel control={<Checkbox color="secondary" name="administrador" value="yes" checked={role.administrador ? true : false} 
                    onChange={event => setRole({...role, administrador:event.target.checked})}/>} label="Administrador" value={role.administrador} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControlLabel control={<Checkbox color="secondary" name="activo" value="yes" checked={role.activo ? true : false} 
                    onChange={event => setRole({...role, activo:event.target.checked})}/>} label="Activa" value={role.activo} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
            </Grid>            
        </Grid>
      </DialogContent>
      <DialogActions>
      { (mode==='DSP' )?
        ( <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button> )
        :
        (<div>
          <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button>          
          <Button onClick={()=>handleClose(mode)} color="primary" >
            {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
          </Button>
        </div>)
      }
      </DialogActions>
    </Dialog>
    }
    <TableContainer className={classes.paper} component={Paper}>
      <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>handleClickOpen('INS', '')} >
          Agregar
        </Button>
      </div>
      <Divider/>
      <Table className={classes.table} aria-label="simple table" size='small'>
        <TableHead style={{backgroundColor:'#34495e'}}>
          <TableRow >
          <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>            
              <TableCell style={{width:'10vh'}} align="left"></TableCell>            
              <TableCell><Typography style={{color:'white'}}>Role</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Administrador</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Activo?</Typography></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>
              <TableCell align="left" >
                <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                  <Search />
                </IconButton>                
              </TableCell>
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                  <Create />
                </IconButton>                
              </TableCell>                            
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('APP', row)}>
                  <FontAwesomeIcon icon={faTabletAlt} /*size = '2x'*/ fixedWidth style={{padding:1.5}}/>
                </IconButton>
              </TableCell>              
              <TableCell component="th" scope="row">
                {row.nombre}
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={row.administrador ? true : false}
                  className={classes.checkbox}/>
              </TableCell>              
              <TableCell align="left">
                <Checkbox
                  checked={row.activo ? true : false}
                  className={classes.checkbox}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}



export default Roles;