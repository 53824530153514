import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormHelperText,
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle, OutlinedInput, InputAdornment,
  Grid, FormControlLabel, TablePagination, Divider, DialogContentText, Typography, FormControl, InputLabel, Tooltip} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import {Create, Delete, Search, Add, Image} from '@material-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faEraser, faSearch } from '@fortawesome/free-solid-svg-icons'

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Autocomplete from '@material-ui/lab/Autocomplete';
import ImageUploading from "react-images-uploading";
import guid from 'guid'
import {apiPath} from '../Config/Config'

//import axios from 'axios'

const useStyles = makeStyles({
  table: {
    minWidth: '650',
  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15
  }, 
  tablerow: {
    height: 33
  },
  margin: {
    margin: 1,
  },
  withoutLabel: {
    marginTop: 3,
  },
  paper:{
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop:20,
    paddingBottom:20,
    marginBottom: 15
  },
  buttonFilter:{
    marginTop:10
  },  
});

function Usuarios () {
    const classes   = useStyles();
  // + Grid
    const [data, setData]                   = useState([])
    const [dataUserRole, setDataUserRole]   = useState([])
    const [isLoading, setIsLoading]         = useState(false)
  // -

  // + Formulario Clase
    const [open, setOpen]           = useState(false)

    const initialValueUsuario       = {
        name : '',
        lastName : '',
        email : '',
        telefono : "",
        user: '',
        password : '',
        documento : '',
        isSocio : false,
        isProfesor : false, 
        tokens : [],
        base64Imagen : null,
        urlImagen : '',
        activo: true,        
        bajaLogica : false
    }
    const [usuario, setUsuario]     = useState(initialValueUsuario)

    const [mode, setMode]           = useState('')
    const [roles, setRoles]         = useState('')

    const initialValueUserRole      = {
        _userID : usuario._id,
        _roleID : null,
        fechaCreacion : null,
        fechaInicio : null,
        fechaFin : null,
        activa : true,
        bajaLogica : false
    }
    const [userRole, setUserRole]   = useState(initialValueUserRole)

    const [images, setImages]       = useState([]); 

    const initialDialogError        = {
        nombre      : false,
        apellido    : false,
        documento   : false,
        email       : false,
        usuario     : false,
        contraseña  : false,
        base64Imagen: false
    }
    const [dialogError, setDialogError] = useState(initialDialogError)  

    const initialValueFilters       = {
        nombre      : '',
        apellido    : '',
        documento   : '',
        activo      : true,
        bajaLogica  : false
    }
    const [filters, setFilters]         = useState(initialValueFilters)
    
  // -

  // + Function Formulario
  const handleClickOpen = async (modo, row) => {
      setMode(modo)
      setShowPassword(false);
      setModificaPassword(false)
      setDialogError(initialDialogError)      
    if (modo === 'INS'){
        setUsuario(initialValueUsuario)
        setImages([])
    }
    else{
        setUsuario(row)
        setImages(row.base64Imagen)
        if (modo === 'ROL')
            setUserRole(initialValueUserRole)
            await getUserRoles(row._id)
    }
    setOpen(true);
  };



  const handleClose = async(modo, validoOK) => {
    switch (modo) {
        case 'INS':
            if (validoOK){
                await agregarUsuarios()
                setOpen(false);
            }
            break;
        case 'UPD':
            if (validoOK){
                await modificarUsuario()
                setOpen(false);
            }
            break
        case 'DLT':
            await eliminarUsuario()
            setOpen(false);
            break;        
      default:
        setOpen(false);
        break
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    if (imageList.length > 0){
        setImages(imageList);
        setUsuario({...usuario, base64Imagen: imageList})  
        setDialogError({...dialogError, base64Imagen: false})
    } else{
        setImages(null);
        setUsuario({...usuario, base64Imagen: null})
        setDialogError({...dialogError, base64Imagen: true})
    }
}; 
  // -

  async function uploadImagen(ubicacion, nombreArchivo){
    try{  
        var Resultado = null
        const baseUrl = apiPath+'uploadImagen'

        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify({
                imagenBase64  : usuario.base64Imagen,
                ubicacion     : ubicacion,
                nombreArchivo : nombreArchivo
            })
        })
        .then(response => response.json())
        .then(responsejson=>{
            if(responsejson){
                Resultado = responsejson.Resultado
            }
        })  
        return Resultado
    }catch(error){
      console.log(error)
    }         
}

// + Funciones Acciones Usuario Roles

    const [nombreBoton, setNombreBoton] = useState('Agregar')

    async function getRoles(){
        try{
            const baseUrl = apiPath+'rolesAll'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
            })
            .then(response => response.json())
            .then((responsejson)=>{
            setRoles(responsejson)
            })
        }catch(error){
            console.log(error)
        }
    }

    async function getUserRoles(_userID){
        try{
            const baseUrl = apiPath+'userRoleAll'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
                body : JSON.stringify({
                    _userID : _userID
                })
            })
            .then(response => response.json())
            .then((responsejson)=>{
                setDataUserRole(responsejson)
            })
        }catch(error){
            console.log(error)
        }
    } 

    async function agregarUserRole(){
        try{  
            setIsLoading(true)

            const pUserRole     = userRole
            pUserRole._roleID   = userRole._roleID._id


            const baseUrl = apiPath+'userRole'
            await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify(pUserRole)
        }).then(response => response.json())
        .then(responsejson=>{
            if (responsejson){
                let dataAux = dataUserRole
                dataAux.push(responsejson.userRole)
                setDataUserRole(dataAux)
            }
        }).finally(()=>setIsLoading(false))
        }catch(error){
            console.log(error)
        }         
    }

    async function modificarUserRole(){
        try{
            const baseUrl = apiPath+'updateUserRole'
            setIsLoading(true)

            console.log(userRole)

            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token'),
                },
                body: JSON.stringify(userRole)
            }).then(response => response.json())
            .then(responsejson => {
                let dataAux = dataUserRole

                dataAux.forEach(item => {
                    if(item._id === responsejson._id){
                        item._roleID        = responsejson._roleID
                        item.fechaInicio    = responsejson.fechaInicio
                        item.fechaFin       = responsejson.fechaFin
                        item.activa         = responsejson.activa
                        return item
                    } 
                })
            }).finally(()=>setIsLoading(false))
        }catch(error){
            console.log(error)
        }
    }

    async function eliminarUserRole(pUserRole){
        try{
            setIsLoading(true)            
            const baseUrl = apiPath+'deleteUserRole'

            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token'),
                },
                body: JSON.stringify({ 
                    _id : pUserRole._id
                })
            }).then(response => response.json())
            .then(responsejson => {
                if (responsejson.Resultado === 'OK'){
                    let dataAux = dataUserRole

                    dataAux.forEach(item => {
                        if (item._id === pUserRole._id)
                        {
                            var i = dataAux.indexOf(item)
                            i !== -1 && dataAux.splice( i, 1 )
                            return item
                        }
                    })
                }
            }).finally(()=>setIsLoading(false))
        }catch(error){
        console.log(error)
        }
    }

    function handleClickLimpiar(){
        setUserRole(initialValueUserRole)
        setNombreBoton('Agregar')
    }

    // + Paginado
        const [rowsPerPageUR, setRowsPerPageUR] = useState(4);
        const [pageUR, setPageUR]               = useState(0);

        const handleChangePageUR = (event, newPage) => {
            setPageUR(newPage);
        };

        const handleChangeRowsPerPageUR = (event) => {
            setRowsPerPageUR(parseInt(event.target.value, 10));
            setPageUR(0);
        };
    // - Paginado

// - 

// + Function Acciones Usuarios.

    async function getUsuarios(){
        try{
            const baseUrl = apiPath+'usersAll'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
                body:JSON.stringify({
                    nombre      : filters.nombre,
                    apellido    : filters.apellido,
                    documento   : filters.documento,
                    activo      : filters.activo,
                    bajaLogica  : filters.bajaLogica
                })
            })
            .then(response => response.json())
            .then((responsejson)=>{
            setData(responsejson)
            })
        }catch(error){
            console.log(error)
        }
    }  

    async function agregarUsuarios(){
        try{  
            const ubicacion     = 'public/usuarios/'
            const nombreArchivo = 'Usuario_' + guid.raw() + '.jpg'
            var Resultado       = ''
            Resultado           = await uploadImagen(ubicacion, nombreArchivo)
      
            if (Resultado === 'OK'){
                const baseUrl = apiPath+'users'
                await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token'),
                },
                body: JSON.stringify({
                    name            : usuario.name,
                    lastName        : usuario.lastName,
                    email           : usuario.email,
                    telefono        : usuario.telefono,
                    user            : usuario.user,
                    password        : usuario.password,
                    documento       : usuario.documento, 
                    isSocio         : usuario.isSocio,
                    isProfesor      : usuario.isProfesor,
                    tokens          : usuario.tokens,
                    base64Imagen    : images,
                    urlImagen       : /*ubicacion*/'usuarios/' + nombreArchivo,
                    activo          : true,        
                    bajaLogica      : false
                })
                }).then(response => response.json())
                .then(responsejson=>{
                    if(responsejson){
                        let dataAux = data
                        dataAux.push(responsejson.user)
                        setData(dataAux)
                    }
                })  
            }
        }catch(error){
        console.log(error)
        } 
    }

    async function modificarUsuario(){
        try{
            const ubicacion     = 'public/usuarios/'
            var nombreArchivo   = usuario.urlImagen.replace("usuarios/", "")
            var Resultado       = 'OK'

            if (images.length > 0 )
                Resultado = await uploadImagen(ubicacion, nombreArchivo)

            if(Resultado === 'OK'){            
                const baseUrl = apiPath+'updateUser'
                await fetch(baseUrl,{
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': window.sessionStorage.getItem('token'),
                    },
                    body: JSON.stringify(usuario)
                }).then(response => response.json())
                    .then(responsejson => {
                    const user = responsejson
                    let dataAux     = data

                    dataAux.forEach(item => {
                        if(item._id === user._id){
                            item.name           = user.name
                            item.lastName       = user.lastName
                            item.documento      = user.documento
                            item.telefono       = user.telefono
                            item.isProfesor     = user.isProfesor 
                            item.email          = user.email
                            item.user           = user.user
                            item.password       = user.password
                            item.base64Imagen   = images
                            item.urlImagen      = user.urlImagen
                            item.bajaLogica     = user.bajaLogica
                            item.activo         = user.activo
                            return item
                        } 
                    })
                    })
                }
        }catch(error){
        console.log(error)
        } 
    }

    async function eliminarUsuario(){
        try{

            const baseUrl = apiPath+'deleteUser'
            await fetch(baseUrl,{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem('token')
                },
                body: JSON.stringify({
                    _id : usuario._id
                })
            }).then(response =>response.json())
                .then(responsejson => {
                if (responsejson.Resultado === 'OK'){
                    let dataAux = data
                    dataAux.forEach(userAux => {
                    if(userAux._id === usuario._id){
                        var i = dataAux.indexOf(userAux)
                        i !== -1 && dataAux.splice( i, 1 )
                        return userAux
                    }
                    })
                }
                }).finally(()=>setIsLoading(false))
            }catch(error){
            console.log(error)
            }     
    }

        // + Paginado
            const [rowsPerPage, setRowsPerPage] = useState(8);
            const [page, setPage]               = useState(0);

            const handleChangePage = (event, newPage) => {
                setPage(newPage);
            };

            const handleChangeRowsPerPage = (event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
            };
        // - Paginado

// - Function Acciones.

  useEffect( ()=>{
    getUsuarios()
    getRoles()
    
    return () => {
        setData([])
        setDataUserRole([])
        setUsuario([])
    }; 
  },[])


// + Show Password

    const [showPassword, setShowPassword]           = useState(false)
    const [modificaPassword, setModificaPassword]   = useState(false)

    const handleClickShowPassword = () => {
        if (mode==='INS'|| modificaPassword)
            setShowPassword(!showPassword );
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

// - Show Password

  return (
    <div className = {classes.main}>
    <CssBaseline />
    {mode==='ROL'?
    (
    <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title" maxWidth='md'>
        <DialogTitle id="form-dialog-title">
            {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Usuario Role
        </DialogTitle>    
        <DialogContent>
            <DialogContentText style={{color:'red'}}>
            { mode==='DLT' ? 
                ('¿Esta seguro que quiere eliminar el usuario?')
                :
                ('')
            }
            </DialogContentText>     

            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Autocomplete id="combo-box-demo"  options={roles} getOptionLabel={(option) => option.nombre} 
                    disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField {...params} label="Role" variant="outlined" 
                    error={userRole._roleID===''} helperText={userRole._roleID==='' ? 'Empty field!' : ' '} required = {true}/>} 
                    value={userRole._roleID} onChange={(event, value) => {setUserRole({...userRole, _roleID : value})}} autoComplete = {false}/>
                </Grid>
                <Grid item xs={3} >
                    <TextField id="datetime-local" label="Fecha inicio" type="datetime-local" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={userRole.fechaInicio?userRole.fechaInicio.substr(0, 16):null} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                    onChange={(event) => {
                        setUserRole({...userRole, fechaInicio : event.target.value})
                    }} fullWidth
                    />
                </Grid>
                <Grid item xs={3} >
                    <TextField id="datetime-local" label="Fecha fin" type="datetime-local" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={userRole.fechaFin?userRole.fechaFin.substr(0, 16):null} disabled={(mode==='DSP'||mode==='DLT')?true:false}
                    onChange={(event) => {
                        setUserRole({...userRole, fechaFin : event.target.value})
                    }} fullWidth/>
                </Grid>
                <Grid item xs={2} >
                    <FormControlLabel control={<Checkbox color="secondary" name="activa" value="yes" checked={userRole.activa ? true : false} 
                        onChange={event => setUserRole({...userRole, activa:event.target.checked})}/>} label="Activa" value={userRole.activa} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
                </Grid>
                <div>

                    <Button variant="contained" color="primary" className={classes.button} startIcon={nombreBoton==='Agregar'?<Add />:<Create />} align="right"
                    onClick={()=>{
                        if (nombreBoton === 'Agregar')
                        {
                            agregarUserRole()
                            handleClickLimpiar()       
                        }      
                        if (nombreBoton === 'Modificar')
                        {
                            modificarUserRole()
                            handleClickLimpiar()
                        }
                    }
                    //handleClickUserRole()
                    } >{nombreBoton}</Button>

                    <Button variant="contained" color="inherit" className={classes.button} startIcon={<FontAwesomeIcon icon={faEraser} /*size = '2x'*/ fixedWidth style={{padding:1.5}}/>} align="right"
                    onClick={()=>handleClickLimpiar()} >Borrar</Button>      

                </div>
                <Table className={classes.table} aria-label="simple table" size='small'>
                    <TableHead style={{backgroundColor:'#34495e'}}>
                        <TableRow >
                            <TableCell style={{width:'5vh'}} align="left"></TableCell>
                            <TableCell style={{width:'8vh'}} align="left"></TableCell>            
                            <TableCell><Typography style={{color:'white'}}>Role</Typography></TableCell>
                            <TableCell align="left"><Typography style={{color:'white'}}>Fecha inicio</Typography></TableCell>
                            <TableCell align="left"><Typography style={{color:'white'}}>Fecha fin</Typography></TableCell>
                            <TableCell align="left"><Typography style={{color:'white'}}>Activa?</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {(dataUserRole).slice(pageUR * rowsPerPageUR, pageUR * rowsPerPageUR + rowsPerPageUR).map((row) => (
                        <TableRow key={row._id} className={classes.tablerow}>
                            <TableCell align="left">
                                <Tooltip title="Modificar">
                                    <IconButton onClick={()=>{
                                        setUserRole(row)
                                        setNombreBoton('Modificar')}}>
                                        <Create />
                                    </IconButton>        
                                </Tooltip>        
                            </TableCell>                            
                            <TableCell align="left">
                                <Tooltip title="Eliminar">
                                    <IconButton onClick={()=>{
                                        eliminarUserRole(row)
                                        handleClickLimpiar()
                                        }}>
                                        
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>    
                        <TableCell component="th" scope="row">
                            {row._roleID.nombre}
                        </TableCell>
                        <TableCell align="left">
                            {row.fechaInicio?row.fechaInicio.substr(8, 2)+'/'+row.fechaInicio.substr(5, 2)+'/'+row.fechaInicio.substr(0, 4)+' - '+row.fechaInicio.substr(11, 5):null}
                        </TableCell>
                        <TableCell align="left">
                            {row.fechaFin?row.fechaFin.substr(8, 2)+'/'+row.fechaFin.substr(5, 2)+'/'+row.fechaFin.substr(0, 4)+' - '+row.fechaFin.substr(11, 5):null}
                        </TableCell>                        
                        <TableCell align="left">
                            <Checkbox
                            checked={row.activa ? true : false}
                            className={classes.checkbox}/>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <div>
                    <TablePagination
                        rowsPerPageOptions={[2, 4, 6]}
                        component="div"
                        count={dataUserRole.length}
                        rowsPerPage={rowsPerPageUR}
                        labelRowsPerPage='Filas por pág.'
                        page={pageUR}
                        onChangePage={handleChangePageUR}
                        onChangeRowsPerPage={handleChangeRowsPerPageUR}
                    />           
                </div>

            </Grid>
        </DialogContent>            
        <DialogActions>
            <Button onClick={()=>handleClose('')} color="primary">
                Cancelar
            </Button>
        </DialogActions>
    </Dialog>
    ):(
        <Dialog open={open} /*onClose={handleClose}*/ aria-labelledby="form-dialog-title" maxWidth='md' > 
        <DialogTitle id="form-dialog-title">
            {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Usuario
        </DialogTitle>
        <DialogContent>
            <DialogContentText style={{color:'red'}}>
            { mode==='DLT' ? 
                ('¿Esta seguro que quiere eliminar el usuario?')
                :
                ('')
            }
            </DialogContentText>           
            <Grid container spacing={3}>
                <Grid item container spacing={3} xs={7} >
                    <Grid item xs={12} >
                        <TextField required id="documento" name="documento" label="Documento" value={usuario.documento} 
                            disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                                setUsuario({...usuario, documento:event.target.value})
                                if (event.target.value)
                                    setDialogError({...dialogError, documento : false})
                                else
                                    setDialogError({...dialogError, documento : true})
                        }} 
                            fullWidth autoComplete="given-name" error={dialogError.documento} helperText={dialogError.documento?"Debe ingresar el documento.":""}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField required id="nombre" name="nombre" label="Nombre" value={usuario.name} 
                            disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                                setUsuario({...usuario, name:event.target.value})
                                if (event.target.value)
                                    setDialogError({...dialogError, nombre : false})
                                else
                                    setDialogError({...dialogError, nombre : true})                            
                        }}
                            fullWidth autoComplete="given-name" error={dialogError.name} helperText={dialogError.name?"Debe ingresar el nombre.":""}/>
                    </Grid>                        
                    <Grid item xs={6}>
                        <TextField required id="apellido" name="apellido" label="Apellido" value={usuario.lastName} 
                            disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                                setUsuario({...usuario, lastName:event.target.value})
                                if (event.target.value)
                                    setDialogError({...dialogError, apellido : false})
                                else
                                    setDialogError({...dialogError, apellido : true})                            
                        }}
                            fullWidth autoComplete="given-name" error={dialogError.lastName} helperText={dialogError.lastName?"Debe ingresar el apellido.":""}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField /*required*/ id="email" name="email" label="Email" value={usuario.email}
                            disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                                setUsuario({...usuario, email:event.target.value})
                                if (event.target.value)
                                    setDialogError({...dialogError, email : false})
                                else
                                    setDialogError({...dialogError, email : true})                            
                            }} 
                            fullWidth autoComplete="given-name" /*error={dialogError.email} helperText={dialogError.descripcion?"Debe ingresar el email.":""}*//>
                    </Grid>     
                    <Grid item xs={6}>
                        <TextField /*required*/ id="telefono" name="telefono" label="Teléfono" value={usuario.telefono}
                            disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                                setUsuario({...usuario, telefono:event.target.value})
                                /*if (event.target.value)
                                    setDialogError({...dialogError, telefono : false})
                                else
                                    setDialogError({...dialogError, email : true})                            
                            */}} 
                            fullWidth autoComplete="given-name" /*error={dialogError.email} helperText={dialogError.descripcion?"Debe ingresar el email.":""}*//>
                    </Grid>         
                </Grid>
                <Grid item xs={5} /*direction='row'*/>
                    <FormControl /*error={dialogError.base64Imagen}*/>
                        <ImageUploading multiple={false} value={images} onChange={onChange} dataURLKey="data_url" >
                            {({imageList, onImageUpload, onImageRemove, dragProps}) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                                {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img src={image.data_url} alt="" width="180" />
                                    {(mode==='DSP' || mode==='DLT')?
                                (<div></div>):(<Button color="secondary" style={{marginLeft:50}} startIcon={<Delete />} onClick={() => onImageRemove(index)}>Eliminar</Button>)}
                                </div>
                                ))}
                                {(mode==='DSP' || mode==='DLT')?
                                (<div></div>):(<Button startIcon={<Image />} style={{marginTop:20}} onClick={onImageUpload} {...dragProps} variant="contained" color="primary">Añadir / Modificar</Button>)}
                            </div>
                            )}
                        </ImageUploading>
                        {/*(dialogError.base64Imagen)?(<FormHelperText>Debe seleccionar una imagen</FormHelperText>):<FormHelperText></FormHelperText>*/}
                    </FormControl>                    
                </Grid>
                <Grid item xs={4}>
                    <TextField required id="usuario" name="usuario" label="Usuario" value={usuario.user}
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} onChange={event => {
                            setUsuario({...usuario, user:event.target.value})
                            if (event.target.value)
                                setDialogError({...dialogError, usuario : false})
                            else
                                setDialogError({...dialogError, usuario : true})                            
                    }} 
                        fullWidth autoComplete="given-name" error={dialogError.usuario} helperText={dialogError.usuario?"Debe ingresar el usuario.":""}/>
                </Grid>
                <Grid item xs={4}>
                    <FormControl variant="outlined" error={dialogError.contraseña} /* helperText={(dialogError.contraseña)?"Debe seleccionar una constraseña.":""} */>
                        <InputLabel required htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                        <OutlinedInput
                            id="password" 
                            type={showPassword ? 'text' : 'password'}
                            value={usuario.password}
                            onChange={event => {
                                setUsuario({...usuario, password:event.target.value})
                                setModificaPassword(true)
                                if (event.target.value)
                                    setDialogError({...dialogError, contraseña : false})
                                else
                                    setDialogError({...dialogError, contraseña : true})                                
                            }}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                helperText={(dialogError.contraseña)?"Debe seleccionar una constraseña.":""}
                                >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                            labelWidth={95}
                            disabled={(mode==='DSP'||mode==='DLT')?true:false}
                            //helperText={(dialogError.contraseña)?"Debe seleccionar una constraseña.":""}
                        />
                        {(dialogError.contraseña)?<FormHelperText>Debe ingresar una constraseña.</FormHelperText>:<FormHelperText></FormHelperText>}
                    </FormControl>                
                </Grid>                
                <Grid item xs={4}>

                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel control={<Checkbox color="secondary" name="socio" checked={usuario.isSocio ? true : false} 
                        onChange={event => {
                            setUsuario({...usuario, isSocio:event.target.checked})
                            }}/>} label="Socio" value={usuario.isSocio} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel control={<Checkbox color="secondary" name="profesor" checked={usuario.isProfesor ? true : false} 
                        onChange={event => {
                            setUsuario({...usuario, isProfesor:event.target.checked})
                        }}/>} label="Profesor" value={usuario.isProfesor} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel control={<Checkbox color="secondary" name="activo" value="yes" checked={usuario.activo ? true : false} 
                        onChange={event => {
                            setUsuario({...usuario, activo:event.target.checked})
                        }}/>} label="Activo" value={usuario.activo} disabled={(mode==='DSP'||mode==='DLT')?true:false}/>
                </Grid>                
            </Grid>
        </DialogContent>
        <DialogActions>
            { (mode==='DSP' )?
                ( <Button onClick={()=>handleClose('')} color="primary">
                    Cancelar
                </Button> )
                :
                (<div>
                <Button onClick={()=>handleClose('')} color="primary">
                    Cancelar
                </Button>          
                <Button onClick={()=>{
                    setDialogError({...dialogError, name: (usuario.name === ''), 
                    lastName : (usuario.lastName === ''), documento : (usuario.documento === ''), usuario : (usuario.user === ''),
                    contraseña : (usuario.password === ''), /*email : (usuario.email === ''),*/ base64Imagen : (usuario.base64Imagen === null)})
                    var validoOK = !((usuario.name === '')||(usuario.lastName === '')||/*(usuario.email === '')||*/(usuario.user === '')||
                    (usuario.password === '')||(usuario.documento === '')||(usuario.base64Imagen === null))
                    handleClose(mode, validoOK)
                }} color="primary" >
                    {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
                </Button>
                </div>)
            }
        </DialogActions>
    </Dialog>
    )}

    <TableContainer className={classes.paper} component={Paper}>
        <Grid container spacing={4} >
            <Grid item xs={4}>
              <TextField required id="nombre" value={filters.nombre} name="nombre" label="Nombre" style={{width:270}}
              onChange={event => setFilters({...filters, nombre : event.target.value})} fullWidth autoComplete="shipping address-line1" multiline rows={1}/>
            </Grid>
            <Grid item xs={4}>
              <TextField required id="apellido" value={filters.apellido} name="apellido" label="Apellido" style={{width:270}}
              onChange={event => setFilters({...filters, apellido : event.target.value})} fullWidth autoComplete="shipping address-line1" multiline rows={1}/>
            </Grid>
            <Grid item xs={4}>
              <TextField required id="documento" value={filters.documento} name="documento" label="Documento" style={{width:270}}
              onChange={event => setFilters({...filters, documento : event.target.value})} fullWidth autoComplete="shipping address-line1" multiline rows={1}/>
            </Grid>            
            <Grid item xs={2}>
                <FormControlLabel style={{marginTop:10, marginLeft:10}} control={<Checkbox color="secondary" name="activo" value={filters.activo} checked={filters.activo ? true : false} />}
                    label="Activo" value={filters.activo} onChange={event => setFilters({...filters, activo : event.target.checked})}/>
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel style={{marginTop:10, marginLeft:10}} control={<Checkbox color="secondary" name="eliminado" value={filters.bajaLogica} checked={filters.bajaLogica ? true : false} />}
                    label="Eliminados" value={filters.bajaLogica} 
                    onChange={event => setFilters({...filters, bajaLogica : event.target.checked})}/>
            </Grid>        
            <Grid item xs={2}>
                <div>
                    <Button  variant="contained"  color="primary" className={classes.buttonFilter}  startIcon={<FontAwesomeIcon icon={faSearch} fixedWidth style={{padding:1.5}}/>} align="right"
                    onClick={()=>getUsuarios()}>Buscar</Button>
                </div>
            </Grid>
        </Grid>      
    </TableContainer>


    <TableContainer className={classes.paper} component={Paper}>
      <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>handleClickOpen('INS', '')} >
          Agregar
        </Button>
      </div>
      <Divider/>
      <Table className={classes.table} aria-label="simple table" size='small'>
        <TableHead style={{backgroundColor:'#34495e'}}>
          <TableRow >
          <TableCell style={{width:'5vh'}} align="left"></TableCell>
              <TableCell style={{width:'5vh'}} align="left"></TableCell>
              <TableCell style={{width:'5vh'}} align="left"></TableCell>
              <TableCell style={{width:'8vh'}} align="left"></TableCell>            
              <TableCell><Typography style={{color:'white'}}>Nombre</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Apellido</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Documento</Typography></TableCell>
              <TableCell align="left"><Typography style={{color:'white'}}>Activo?</Typography></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>
                <TableCell align="left" >
                    <Tooltip title="Visualizar">
                        <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                            <Search />
                        </IconButton>                
                    </Tooltip>
                </TableCell>
                <TableCell align="left">
                    <Tooltip title="Modificar">
                        <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                            <Create />
                        </IconButton>        
                    </Tooltip>        
                </TableCell>                            
                <TableCell align="left">
                    <Tooltip title="Eliminar">
                        <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell align="left">
                    <Tooltip title="Roles">
                        <IconButton onClick={()=>{
                            
                            handleClickOpen('ROL', row)}}>
                            <FontAwesomeIcon icon={faUsers} /*size = '2x'*/ fixedWidth style={{padding:1.5}}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>              
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">
                {row.lastName}
              </TableCell>
              <TableCell align="left">
                {row.documento}
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={row.activo ? true : false}
                  className={classes.checkbox}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}
export default Usuarios;