import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Checkbox,CssBaseline, TextField, Dialog, DialogActions,DialogContent, DialogTitle, 
  Grid, TablePagination, Divider, DialogContentText} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Create, Delete, Search, Add} from '@material-ui/icons';
import moment from 'moment'

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {apiPath} from '../Config/Config'
import { } from '../Services/PersonaService'

const useStyles = makeStyles({
  table: {
    minWidth: '650',

  },
  main:{
    paddingLeft:10,
    paddingRight:10,
    paddingTop: 20
    
  },
  button:{
    marginTop:15, 
    marginBottom:15,
    marginLeft:15
  }, 
  tablerow: {
    height: 33
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Personas ()
 {
  const classes     = useStyles();
  const tipo        = ['Administrativo', 'Profesor']
  const planes      = ['Plan Parcial', 'Plan Mensual']
  const metodoPago  = ['Efectivo', 'Transferencia Bancaria', 'Agencia de pago']

  const initialValuePersona  = {
      _id : null,
      nroPersona : 0,
      primerNombre : null, 
      primerApellido : null,
      email: null,
      fechaNacimiento : null,
      documento : null,
      nacionalidad : null,
      domicilio : null,
      telefonoFijo : null,
      telefonoCelular : null,
      vencimientoCarnetSalud : null, //moment().format()
      prestadorSalud : null,
      emergencia : null,
      activa : true,
      bajaLogica : false, 
      invitado : false,
      funcionario : false,
      socio : false,
      _funcionarioID : {_id : null, nroFuncionario: 0, tipo: null, fechaIngreso: null, fechaEgreso: null, activa: true, bajaLogica: false},
      _socioID : {_id: null, nroSocio: 0, plan: null, metodoPago: null, fechaIngreso: null, fechaEgreso: null, activa: true, bajaLogica: false},
      fechaCreacion: moment().format()
  }

  const [persona, setPersona]         = useState(initialValuePersona)

  // + Grid
    const [data, setData]               = useState([])
  // -    

  // + Formulario Persona
    const [open, setOpen]       = useState(false)
    const [mode, setMode]       = useState('')
  // -

  // + Tabs Persona
    const [value, setValue] = useState(0);

    function handleChange(event, newValue){
      setValue(newValue);
    };

    function a11yProps(index) {
      return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
      };
    }
    
    function LinkTab(props) {
      return (
        <Tab
          component="a"
          onClick={(event) => {
            event.preventDefault();
          }}
          {...props}
        />
      );
    }
  // -

  // + Function Formulario
  const handleClickOpen = (modo, persona) => {
    setMode(modo)
    setValue(0)    
    if (modo === 'INS')
    {
      setPersona(initialValuePersona)
    }else{
      setPersona(persona)
    }
    setOpen(true);
  };

  const handleClose = async(modo) => {
    switch (modo) {
      case 'INS':
        await agregarPersona()
        setOpen(false);
        break;
      case 'DLT':
        await eliminarLocal()
        setOpen(false);
        break;
      case 'UPD':
        await modificarPersona()
        setOpen(false);
        break
      default:
        setOpen(false);
        break
    }
  };
  // -

  // + Obtengo Personas
  async function getPersonas(){
    try{
      const baseUrl = apiPath+'personasAll'
      await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token')
          },
      })
      .then(response => response.json())
      .then((responsejson)=>{
        setData(responsejson)
      })
    }catch(error){
      console.log(error)
    }
  }  
  // -

  // + Function Acciones.
  async function agregarPersona(){
    try{  
        const baseUrl = apiPath+'persona'
        await fetch(baseUrl,{
          method: 'post',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': window.sessionStorage.getItem('token'),
          },
          // Parametro Alta Persona
          body: JSON.stringify(persona)

      }).then(response => response.json())
        .then(responsejson =>{
           if(responsejson){
            //  Agergo persona.
            let dataAux = data
            dataAux.push(responsejson)
            setData(dataAux)
          } 
        })  
    }catch(error){
      console.log(error)
    } 
  }

    const modificarPersona = async() => {
     try{
        const baseUrl = apiPath+'updatePersona'
        //setIsLoading(true)
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token'),
            },
            body: JSON.stringify(persona)
        }).then(response => response.json())
          .then(responsejson => {
            const personaUpdate = responsejson
            let dataAux         = data

            console.log(personaUpdate)

            dataAux.forEach(item => {
              // Update datos
              if(item._id === personaUpdate._id){
                // Update Persona
                item.primerNombre           = personaUpdate.primerNombre
                item.primerApellido         = personaUpdate.primerApellido
                item.documento              = personaUpdate.documento
                item.fechaNacimiento        = personaUpdate.fechaNacimiento
                item.nacionalidad           = personaUpdate.nacionalidad
                item.domicilio              = personaUpdate.domicilio
                item.telefonoFijo           = personaUpdate.telefonoFijo
                item.telefonoCelular        = personaUpdate.telefonoCelular
                item.prestadorSalud         = personaUpdate.prestadorSalud
                item.emergencia             = personaUpdate.emergencia
                item.vencimientoCarnetSalud = personaUpdate.vencimientoCarnetSalud
                item.email                  = personaUpdate.email
                item.invitado               = personaUpdate.invitado
                item.socio                  = personaUpdate.socio
                item.funcionario            = personaUpdate.funcionario
                item.activa                 = personaUpdate.activa
                item.bajaLogica             = personaUpdate.bajaLogica

                // Update Funcionario
                item._funcionarioID.tipo          = personaUpdate._funcionarioID.tipo
                item._funcionarioID.fechaIngreso  = personaUpdate._funcionarioID.fechaIngreso
                item._funcionarioID.fechaEgreso   = personaUpdate._funcionarioID.fechaEgreso
                item._funcionarioID.activa        = personaUpdate._funcionarioID.activa
                item._funcionarioID.bajaLogica    = personaUpdate._funcionarioID.bajaLogica

                // Update Socio
                item._socioID.plan          = personaUpdate._socioID.plan
                item._socioID.metodoPago    = personaUpdate._socioID.metodoPago
                item._socioID.fechaIngreso  = personaUpdate._socioID.fechaIngreso
                item._socioID.fechaEgreso   = personaUpdate._socioID.fechaEgreso
                item._socioID.activa        = personaUpdate._socioID.activa
                item._socioID.bajaLogica    = personaUpdate._socioID.bajaLogica
                
                return item
              }
            })
          })
      }catch(error){
        console.log(error)
      } 
    }

    const eliminarLocal = async() => {
  /*     try{
        const baseUrl = apiPath+'deleteUbicacion'
        await fetch(baseUrl,{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': window.sessionStorage.getItem('token')
            },
            body: JSON.stringify({
              _id : id
            })          
        }).then(response =>response.json())
          .then(responsejson => {
            if (responsejson.Resultado === 'OK'){
              let dataAux = data
              dataAux.forEach(local => {
                if(local._id === id){
                  var i = dataAux.indexOf(local)
                  i !== -1 && dataAux.splice( i, 1 )
                  return local
                }
              })
            }
          })
      }catch(error){
        console.log(error)
      }     */
    }
  // -

  useEffect( ()=>{
    getPersonas()
    return () => {
    }; 
  },[])

// + Paginado
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [page, setPage]               = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
// - Paginado

// Render Page
  return (
    <div className = {classes.main}>
    <CssBaseline />
    <Dialog open={open}  aria-labelledby="form-dialog-title" /*fullWidth={true}*/ maxWidth='md'>
      <DialogTitle id="form-dialog-title">
      {mode==='INS'?'Nueva':(mode ==='DLT'?'Eliminar':(mode ==='UPD'?'Modificar':''))} Persona</DialogTitle>
        <DialogContent>
          <DialogContentText style={{color:'red'}}>
            { mode==='DLT' ? 
              ('¿Esta seguro que quiere eliminar el local?')
              :
              ('')
            }
          </DialogContentText>

          <AppBar position="static" /* Tabs */ >
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <LinkTab label="Datos" href="/drafts" {...a11yProps(0)} />
              <LinkTab disabled={persona.funcionario?false:true} label="Funcionario" href="/trash" {...a11yProps(1)} />
              <LinkTab disabled={persona.socio?false:true} label="Socio" href="/spam" {...a11yProps(2)} />
              <LinkTab disabled={persona.funcionario||persona.socio?false:true}label="Seguridad" href="/spam" {...a11yProps(3)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0} /* Tab Persona */ >
          <Grid container spacing={3}>
              <Grid item xs={2}>
                  <TextField id="nroPersona" value={persona.nroPersona} name="nroPersona" label="Nro. Persona" 
                      onChange={(event, value) => setPersona({...persona, nroPersona : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled variant='outlined'/>                 
              </Grid> 
              <Grid item xs={4} >
                  <TextField required id="primerNombre" value={persona.primerNombre} name="primerNombre" label="Primer Nombre" 
                      onChange={(event, value) => {
                        setPersona({...persona, primerNombre : event.target.value})}
                      } fullWidth autoComplete="shipping address-line1" disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>
              <Grid item xs={4}>
                  <TextField required id="primerApellido" value={persona.primerApellido} name="primerApellido" label="Primer Apellido" 
                      onChange={(event, value) => {
                        setPersona({...persona, primerApellido : event.target.value})}
                      } fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>
              <Grid item xs={3}>
                  <TextField required id="documento" value={persona.documento} name="documento" label="Documento" 
                      onChange={(event, value) => setPersona({...persona, documento : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>
              <Grid item xs={3}>
                  <TextField required id="date" label="Fecha Nacimiento" type="date" className={classes.textField}
                      InputLabelProps={{ shrink: true, }} value={persona.fechaNacimiento?persona.fechaNacimiento.substr(0,10):null} 
                      onChange={(event, value) => {
                          setPersona({...persona, fechaNacimiento:event.target.value})}} fullWidth disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>
              </Grid>
              <Grid item xs={4}>
                  <TextField required id="nacionalidad" value={persona.nacionalidad} name="nacionalidad" label="Nacionalidad" 
                      onChange={(event, value) => setPersona({...persona, nacionalidad : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>

              <Grid item xs={6}>
                  <TextField required id="domicilio" value={persona.domicilio} name="domicilio" label="Domicilio" 
                      onChange={(event, value) => setPersona({...persona, domicilio : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>
              <Grid item xs={3}>
                  <TextField required id="telefonoFijo" value={persona.telefonoFijo} name="telefonoFijo" label="Teléfono Fijo" 
                      onChange={(event, value) => setPersona({...persona, telefonoFijo : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>
              <Grid item xs={3}>
                  <TextField  id="telefonoCelular" value={persona.telefonoCelular} name="telefonoCelular" label="Teléfono Celular" 
                      onChange={(event, value) => setPersona({...persona, telefonoCelular : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>            
              <Grid item xs={4}>
                  <TextField  id="prestadorSalud" value={persona.prestadorSalud} name="prestadorSalud" label="Prestador Salud" 
                      onChange={(event, value) => setPersona({...persona, prestadorSalud : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>
              <Grid item xs={4}>
                  <TextField  id="emergencia" value={persona.emergencia} name="emergencia" label="Emergencia" 
                      onChange={(event, value) => setPersona({...persona, emergencia : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/> 
              </Grid>
              <Grid item xs={3}>
                  <TextField required id="date" label="Venc. Carnet Salud" type="date" className={classes.textField}
                      InputLabelProps={{ shrink: true, }} value={persona.vencimientoCarnetSalud?persona.vencimientoCarnetSalud.substring(0,10):null} 
                      onChange={(event, value) => {setPersona({...persona, vencimientoCarnetSalud:event.target.value})}} fullWidth 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>
              </Grid>
              <Grid item xs={4}>
                  <TextField required id="email" value={persona.email} name="email" label="Correo electrónico" 
                      onChange={(event, value) => setPersona({...persona, email : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                      disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>                 
              </Grid>          
              <Grid item xs={8}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Naturaleza</FormLabel>
                  <FormGroup aria-label="position" row>

                    <Grid item xs={4}>
                      <FormControlLabel value={persona.invitado} control={<Checkbox color="secondary" checked={persona.invitado ? true : false}/>} 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} label="Invitado?" labelPlacement="end"
                        onChange={event => setPersona({...persona, invitado : event.target.checked})}/>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel value={persona.funcionario} control={<Checkbox color="secondary" checked={persona.funcionario ? true : false}/>} 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} label="Funcionario?" labelPlacement="end"
                        onChange={event => setPersona({...persona, funcionario : event.target.checked})}/>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel value={persona.socio} control={<Checkbox color="secondary" checked={persona.socio ? true : false}/>} 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} label="Socio?" labelPlacement="end"
                        onChange={event => setPersona({...persona, socio : event.target.checked})}/>
                    </Grid>
                  </FormGroup>
                </FormControl>  
              </Grid>                                   
          </Grid>



          </TabPanel>
          
          <TabPanel value={value} index={1} /* Tab Funcionario */ >
            <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField required id="nrofuncionario" value={persona.funcionario?persona._funcionarioID.nroFuncionario:null} name="email" label="Nro Funcionario"
                    fullWidth autoComplete="shipping address-line1" disabled variant='outlined'/>  
                </Grid> 
                <Grid item xs={6}>
                    <Autocomplete  id="combo-box-demo" options={tipo} getOptionLabel={(option) => option} style={{ width: 250 }} 
                    disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField required {...params} label="Tipo" variant="outlined" />}
                    onChange={(event, value) =>{setPersona({...persona,  _funcionarioID:{...persona._funcionarioID, tipo : value}}) }} value={persona.funcionario?persona._funcionarioID.tipo:null}/>
                </Grid>            
                <Grid item xs={6}>
                  <TextField required id="date" label="Fecha Ingreso" type="date" className={classes.textField}
                      InputLabelProps={{ shrink: true, }} value={persona.funcionario?(persona._funcionarioID.fechaIngreso?persona._funcionarioID.fechaIngreso.substr(0,10):null):null} 
                      onChange={(event, value) => {
                          setPersona({...persona, _funcionarioID:{...persona._funcionarioID, fechaIngreso : event.target.value}})}} fullWidth 
                          disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>
                </Grid>
                <Grid item xs={6}>
                  <TextField required id="date" label="Fecha Egreso" type="date" className={classes.textField}
                      InputLabelProps={{ shrink: true, }} value={persona.funcionario?(persona._funcionarioID.fechaEgreso?persona._funcionarioID.fechaEgreso.substr(0,10):null):null} 
                      onChange={(event, value) => {
                          setPersona({...persona, _funcionarioID : {...persona._funcionarioID, fechaEgreso : event.target.value}})}} fullWidth 
                          disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>
                </Grid>              
              </Grid>    
            </TabPanel>

          <TabPanel value={value} index={2} /* Tab Socio */ >
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField required id="nroSocio" value={persona.socio?persona._socioID.nroSocio: 0} name="nroSocio" label="Nro Socio"
                  fullWidth autoComplete="shipping address-line1" disabled variant='outlined'/>  
              </Grid>            
              <Grid item xs={4}>
                <TextField required id="date" label="Fecha Ingreso" type="date" className={classes.textField}
                    InputLabelProps={{ shrink: true, }} value={persona.socio?(persona._socioID.fechaIngreso?persona._socioID.fechaIngreso.substr(0,10):null):null} 
                    onChange={(event, value) => {
                      setPersona({...persona, _socioID:{...persona._socioID, fechaIngreso:event.target.value}})}} fullWidth 
                        disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>
                </Grid>
                <Grid item xs={4}>
                  <TextField required id="date" label="Fecha Egreso" type="date" className={classes.textField}
                      InputLabelProps={{ shrink: true, }} value={persona.socio?(persona._socioID.fechaEgreso?persona._socioID.fechaEgreso.substr(0,10):null):null} 
                      onChange={(event, value) => {
                        setPersona({...persona, _socioID:{...persona._socioID, fechaEgreso:event.target.value}})}} fullWidth 
                          disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>
                </Grid>                                      
              <Grid item xs={4}>
                <Autocomplete  id="combo-box-demo" options={planes} getOptionLabel={(option) => option} 
                  disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField required {...params} label="Plan" variant="outlined" />}
                  onChange={(event, value) =>{setPersona({...persona, _socioID:{...persona._socioID, plan : value}})}} value={persona._socioID?persona._socioID.plan:null}/>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete  id="combo-box-demo" options={metodoPago} getOptionLabel={(option) => option} 
                  disabled={(mode==='DSP'||mode==='DLT')?true:false} renderInput={(params) => <TextField required {...params} label="Método Pago" variant="outlined" />}
                  onChange={(event, value) =>{setPersona({...persona, _socioID:{...persona._socioID, metodoPago : value}})}} value={persona._socioID?persona._socioID.metodoPago:null}/>
              </Grid>            
              <Grid item xs={4}>
                <FormControlLabel value={persona.socio?persona.socio.activa:false} control={<Checkbox color="secondary" checked={persona.socio?(persona._socioID.activa ? true : false):false}/>} 
                  disabled={(mode==='DSP'||mode==='DLT')?true:false} label="Activo?" labelPlacement="end"
                  onChange={event => setPersona({...persona, _socioID:{...persona._socioID, activa : event.target.checked}})}/>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={3} /* Tab Seguridad */ >

          <Grid item xs={4}>
                <TextField required id="usuario" value={persona.usuario} name="usuario" label="Usuario" 
                    onChange={(event, value) => setPersona({...persona, usuario : event.target.value})} fullWidth autoComplete="shipping address-line1" 
                     disabled={(mode==='DSP'||mode==='DLT')?true:false} variant='outlined'/>                 
            </Grid>

        </TabPanel>
        
        </DialogContent>
      <DialogActions /* Acciones Tab */ >
      { (mode==='DSP' )?
        ( <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button> )
        :
        (<div>
          <Button onClick={()=>handleClose('')} color="primary">
            Cancelar
          </Button>          
          <Button onClick={()=>handleClose(mode)} color="primary" >
            {mode==='INS'?'Agregar':(mode ==='DLT'?'Eliminar':'Modificar')}
          </Button>
        </div>)
      }
      </DialogActions>
    </Dialog>

    <TableContainer className={classes.paper} component={Paper}>
      <div>
        <Button variant="contained" color="primary" className={classes.button} startIcon={<Add />} align="right"
        onClick={()=>handleClickOpen('INS', '')} >
          Agregar
        </Button>
      </div>
      <Divider/>

      <Table className={classes.table} aria-label="simple table" size='small' /* Grid Personas */ >
        <TableHead style={{backgroundColor:'#00e676'}}>
          <TableRow >
          <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>
              <TableCell style={{width:'10vh'}} align="left"></TableCell>            
              <TableCell>Documento</TableCell>
              <TableCell align="left">Persona</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Activa?</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {(data).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <TableRow key={row._id} className={classes.tablerow}>
              <TableCell align="left" >
                <IconButton onClick={()=>handleClickOpen('DSP', row)}>
                  <Search color='primary' />
                </IconButton>                
              </TableCell>
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('UPD', row)}>
                  <Create />
                </IconButton>                
              </TableCell>                            
              <TableCell align="left">
                <IconButton onClick={()=>handleClickOpen('DLT', row)}>
                  <Delete />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {row.documento}
              </TableCell>
              <TableCell align="left">
                {row.primerNombre}
              </TableCell>
              <TableCell align="left">
                {row.primerApellido}
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={row.activa ? true : false}
                  className={classes.checkbox}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Filas por pág.'
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />           
      </div>
    </TableContainer>
    </div>
  );
}

export default Personas;