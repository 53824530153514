import React from 'react';
import ListItemLink from './ListItemLink'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCalendarAlt, faFileAlt } from '@fortawesome/free-regular-svg-icons'
import { faRunning, faMapMarkerAlt, faUsersCog, faUserShield, faImages } from '@fortawesome/free-solid-svg-icons'

export const mainListItems = (

  <div>
    <ListItemLink to="/Reservas"  primary="Calendario"   icon={<FontAwesomeIcon icon={faCalendar} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/>}/>
  </div>
);
//    <ListItemLink to="/home"      primary="Dashboard" icon={<Dashboard />} />
//<ListItemLink to="/Personas"  primary="Personas"   icon={<Person />} />

export const mainListGestion = (
  <div>
    <ListItemLink to="/agendas"  primary="Agendas"        icon={<FontAwesomeIcon icon={faCalendarAlt} size = '2x' fixedWidth style={{padding:1.5, color:'#d94043'}}/>} />        
    <ListItemLink to="/clases"    primary="Clases"        icon={<FontAwesomeIcon icon={faRunning} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/>} />
    <ListItemLink to="/locales"  primary="Locales"        icon={<FontAwesomeIcon icon={faMapMarkerAlt} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/>} />
    <ListItemLink to="/Plantillas"  primary="Plantillas"  icon={<FontAwesomeIcon icon={faFileAlt} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/>} />
    <ListItemLink to="/slides"  primary="Slides"          icon={<FontAwesomeIcon icon={faImages} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/>} />
  </div>
);

export const mainListSeguridad = (
  <div>
    <ListItemLink to="/usuarios"  primary="Usuarios"  icon={<FontAwesomeIcon icon={faUserShield} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/>}/>
    <ListItemLink to="/roles"     primary="Roles"     icon={<FontAwesomeIcon icon={faUsersCog} size = '2x' fixedWidth  style={{padding:1.5, color:'#d94043'}}/>}/>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListItemLink to="/generacionagendas"  primary="Gen. Agendas" /*icon={<FontAwesomeIcon  size = '2x' fixedWidth  style={{padding:1.5}}/>}*//>
    <ListItemLink to="/generacionagendasmasiva"  primary="Gen. Agendas Masivo" /*icon={<FontAwesomeIcon  size = '2x' fixedWidth  style={{padding:1.5}}/>}*//>
  </div>
);